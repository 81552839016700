import { useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { heightConfimationStyles } from "../../../assets/styles/toast";
import { getData } from "../../../services";
import { fetchGroupData } from "../../../store/slices/savedSlice";
import { useSubscription } from "../../../SubscriptionContext";
import SelectDropdown from "../../atoms/Dropdown";
import Button from "../../atoms/ResetBtn/button";
import { convertToValueLabel } from "../../pages/dashboard/SavedList";
import Modal from "../Modal/Normal";

const SaveAll = ({
  type,
  filters,
  saveType,
  search,
  setRefetch,
  locationFilter,
  tagFilter,
  createdFilter,
  updatedFilter,
  aum,
  fundingGoal,
  directDealTypeFilter,
  directDealStrategyFilter,
  teamFilter,
  investor_types,
}) => {
  const { handleUserClick, isSubscriptionExpired } = useSubscription();

  const [cookies] = useCookies(["t", "cuid"]);
  const [showModal, setShowModal] = useState(false);
  const [newGroupName, setNewGroupName] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const apis = {
    ManagerCompany: "SavedList/addProductCompanySavedList",
    ManagerPeople: "SavedList/adProductPeopleSavedList",
    InvestorCompany: "SavedList/addInvestorCompanySavedList",
    InvestorPeople: "SavedList/addInvestorPeopleSavedList",
  };

  const ManagerCompanydata = {
    search_query: search,

    product_structure: filters?.["Product Structure"]?.length
      ? JSON.stringify(
          filters["Product Structure"].map((structure) => structure?.value)
        )
      : null,

    product_type:
      directDealTypeFilter && filters?.["Product Type"]?.length > 0
        ? JSON.stringify([
            ...directDealTypeFilter,
            ...filters["Product Type"].map((category) => category?.value),
          ])
        : directDealTypeFilter?.length
        ? JSON.stringify(directDealTypeFilter)
        : filters?.["Product Type"]?.length
        ? JSON.stringify(
            filters["Product Type"].map((category) => category?.value)
          )
        : null,

    product_strategy:
      directDealStrategyFilter && filters?.["Product Strategy"]?.length > 0
        ? JSON.stringify([
            ...directDealStrategyFilter,
            ...filters["Product Strategy"].map((category) => category?.value),
          ])
        : directDealStrategyFilter?.length
        ? JSON.stringify(directDealStrategyFilter)
        : filters?.["Product Strategy"]?.length
        ? JSON.stringify(
            filters["Product Strategy"].map((category) => category?.value)
          )
        : null,
    sector: filters?.Sector?.length
      ? JSON.stringify(filters["Sector"].map((sector) => sector?.value))
      : null,

    geography: filters?.Geography?.length
      ? JSON.stringify(
          filters["Geography"].map((geography) => geography?.value)
        )
      : null,

    country:
      locationFilter?.Country &&
      JSON.stringify(locationFilter["Country"].map((country) => country.label)),

    state:
      locationFilter?.State &&
      JSON.stringify(locationFilter["State"].map((state) => state.label)),

    city:
      locationFilter?.City &&
      JSON.stringify(locationFilter["City"].map((city) => city.label)),

    tags: JSON.stringify(tagFilter),

    created_start_date: createdFilter?.created_start_date,
    created_end_date: createdFilter?.created_end_date,
    updated_start_date: updatedFilter?.updated_start_date,
    updated_end_date: updatedFilter?.updated_end_date,
    min_aum: aum?.min,
    max_aum: aum?.max,
    min_funding: fundingGoal?.min,
    max_funding: fundingGoal?.max,

    ...(saveType === "ManagerPeople" && {
      teams: teamFilter?.length > 0 ? JSON.stringify(teamFilter) : null,
    }),

    ...((saveType === "InvestorCompany" || saveType === "InvestorPeople") && {
      investor_types:
        investor_types?.length > 0 ? JSON.stringify(investor_types) : null,
    }),
  };

  const dispatch = useDispatch();

  const { company, people } = useSelector((state) => state.savedGroups);

  const handleSave = async () => {
    setShowModal(false);
    // Ensure only one of newGroupName or selectedGroup is set

    toast.success(
      `${type === "Company" ? "Companies" : "Profiles"} saved to ${
        selectedGroup || newGroupName
      } group.`,
      { style: heightConfimationStyles, duration: 1000 }
    );

    const res = await getData({
      endpoint: apis[saveType],
      // data: {
      //   map_type: type,
      //   group: selectedGroup || newGroupName,
      // },
      params: {
        ...ManagerCompanydata,
        user_id: cookies.cuid,
        group: selectedGroup || newGroupName,
      },
      token: cookies.t,
    });

    if (res) {
      setRefetch((prev) => !prev);
      setNewGroupName(null);
      setSelectedGroup(null);
    }

    if (newGroupName) {
      // Fetch updated groups based on the selected type
      const groupType = type.toLowerCase(); // Convert type to lowercase for consistency
      dispatch(fetchGroupData({ type: groupType, userId: cookies.cuid }));
    }
  };

  const data =
    type === "Company"
      ? convertToValueLabel(company)
      : convertToValueLabel(people);

  const handleCancel = () => {
    setShowModal(false);
    setSelectedGroup(null);
    setNewGroupName(null);
  };

  const handleClick = (e) => {
    if (isSubscriptionExpired) {
      handleUserClick(e);
      return;
    }

    setShowModal(true);
  };
  return (
    <>
      <Button label={"Save All"} type={"small"} onClick={handleClick} />
      {showModal && (
        <Modal
          title="Select or create group"
          isActive={showModal}
          id={1}
          onClose={() => setShowModal(false)}
          width="500px"
          overflowStyle={{ overflow: "visible" }}
        >
          <label>Select an existing group</label>
          <SelectDropdown
            placeholder="Select Group"
            onChange={(option) => {
              setSelectedGroup(option);
              setNewGroupName(""); // Clear newGroupName when an existing group is selected
            }}
            selectedValue={selectedGroup}
            data={data}
          />
          <div className="d-flex align-items-center justify-content-center my-2">
            <span className="fw-bold">OR</span>
          </div>
          <div className="d-flex flex-column">
            <label>Create a new group</label>
            <input
              placeholder="Enter group name"
              value={newGroupName}
              onChange={(e) => {
                setNewGroupName(e.target.value);
                setSelectedGroup(null); // Clear selectedGroup when entering a new group name
              }}
            />
          </div>
          <div className="d-flex gap-3 mb-3 w-100 mt-4">
            <button
              className="btn btn-primary"
              style={{ flex: 1 }}
              onClick={handleSave}
              disabled={!selectedGroup && !newGroupName}
            >
              Save to Group
            </button>
            <button
              className="btn btn-outline-dark"
              style={{ flex: 1 }}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SaveAll;
