import Heading from "../../../atoms/Heading";
import "./ImageContent.scss";

export const ImageContentHeading = ({
  Heading: heading,
  Information,
  Content,
  onClick1,
}) => {
  return (
    <div className="Investor-DataBase-Content-Header">
      <div className="Investor-DataBase-Content-Heading-container">
        {/* <h1 className='Investor-DataBase-Content-Heading'>Marketing Innovation</h1> */}
        <div className="mb-1">
          <Heading type="sub" title={heading} />
        </div>
        <span className="Investor-DataBase-Content-Information">
          {Information}
        </span>
        
      </div>
      
      <div>
      {Content && (
        <button
          className="Investor-DataBase-Content-Header-btn"
          onClick={onClick1}
        >
          {Content}
        </button>
      )}
      </div>
      
    </div>
  );
};

export const ImageContentContainer = ({
  style,
  ImagePath,
  Heading,
  Content1,
  Content2,
}) => {
  return (
    <div className="Image-Content-Container" style={style}>
      <div
        className="Image-Content-Container-Pic"
        style={{
          background: `url(${ImagePath}) lightgray 50% top / cover no-repeat`,
        }}
      ></div>
      <div className="Image-Content-Container-Information">
        <h1 className="h1-j mb-0">{Heading}</h1>
        <span>{Content1}</span>
        <span className="Information-content-2">{Content2}</span>
      </div>
    </div>
  );
};
const ImageContent = ({ children }) => {
  return (
    <>
      <div className="ImageContent-Container">{children}</div>
    </>
  );
};

export default ImageContent;
