export const modifyDuplicateLabels = (
  items,
  labelKey = "label",
  identifierKey = "structure_name"
) => {
  // Step 1: Count occurrences of each label
  const labelCount = items.reduce((acc, item) => {
    acc[item[labelKey]] = (acc[item[labelKey]] || 0) + 1;
    return acc;
  }, {});

  // Step 2: Map over the items to modify labels if duplicates are found
  const modifiedItems = items.map((item) => ({
    ...item,
    [labelKey]:
      labelCount[item[labelKey]] > 1
        ? `${item[labelKey]} (${item[identifierKey]})`
        : item[labelKey],
  }));

  return modifiedItems;
};

export const groupByLabel = (data) =>
  data.reduce((acc, curr) => {
    const existingGroup = acc.find((item) => item.label === curr.label);

    if (existingGroup) {
      // If the group already exists, push the new value and data
      existingGroup.value.push(curr.value);
      existingGroup.data.push({
        value: curr.value,
        label: curr.structure_name,
      });
    } else {
      // Check if there are other objects with the same label
      const duplicateItems = data.filter((item) => item.label === curr.label);

      if (duplicateItems.length > 1) {
        // If there are multiple items with the same label, create a group
        acc.push({
          label: curr.label,
          value: [curr.value],
          nested: true,
          data: [{ value: curr.value, label: curr.structure_name }],
        });
      } else {
        // If no duplicate, just push the current item as is
        acc.push(curr);
      }
    }

    return acc;
  }, []);
