// product name prop to pass the product name in the shared link
// type - to set the visiblity to view or not
// visibility type - for the shared visibility

import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import { confimationStyles } from "../../../../../assets/styles/toast";
import { convertSecondsToTime } from "../../../../../helpers/secondsToHours";
import ShareIcon from "../../../../Icons/ShareIcon";
import VideoIcon from "../../../../Icons/videoIcon";
import CheckboxWrapper from "../../../../atoms/CheckBox";
import IconCircle from "../../../../atoms/IconCircle";
import Modal from "../../../../molecules/Modal/Normal";
import ShareLink from "../../../../organisms/ShareFunctionality/ShareLink";
import ShareLinkEmail from "../../../../organisms/ShareFunctionality/ShareLinkEmail";
import "./components.scss";

const Video = ({ data, type = "", visibility_type, prod_name, company_id }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selected, setSelected] = useState({
    transcript: "",
    description: "",
    link: "",
  });
  const [generateURL, setGenerateURL] = useState();
  const [isCopy, setIsCopy] = useState(false);
  const [expiry, setExpiry] = useState(false);
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalId: "",
    title: "",
    isViewModalOpen: false,
  });

  useEffect(() => {
    setVideos(data);
    setSelected(data[0] || { transcript: "", description: "", selected: "" });
  }, [data]);

  const getEmbedUrl = (link) => {
    const youtubeRegex =
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
    const vimeoRegex = /vimeo\.com\/(?:video\/|manage\/videos\/)?(\d+)/;

    const youtubeMatch = link?.match(youtubeRegex);
    const vimeoMatch = link?.match(vimeoRegex);

    if (youtubeMatch) {
      return `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    } else if (vimeoMatch) {
      return `https://player.vimeo.com/video/${vimeoMatch[1]}`;
    } else {
      return null;
    }
  };

  const embedUrl = getEmbedUrl(data?.link);

  const handleLoad = () => {
    setLoading(false);
  };

  const handleSelectAll = () => {
    if (selectedIds.length === videos.length) {
      setSelectedIds([]);
    } else {
      const allVideoIds = videos.map((video) => video.video_id);
      setSelectedIds(allVideoIds);
    }
  };

  const handleSingleSelect = (video_id) => {
    const index = selectedIds.indexOf(video_id);
    if (index === -1) {
      setSelectedIds([...selectedIds, video_id]);
    } else {
      setSelectedIds(selectedIds.filter((id) => id !== video_id));
    }
  };

  // Sharing videos
  const handleShare = (data) => {
    const modalId = uuidv4();
    setModalState((prev) => ({
      ...prev,
      title: "Share Content",
      modalId: modalId,
      FormData: data,
      isModalOpen: true,
    }));
  };

  const handleShareLinkEmail = () => {
    const modalId = uuidv4();
    setModalState((prev) => ({
      ...prev,
      title: "Share Content",
      modalId: modalId,
      isViewModalOpen: true,
    }));
  };

  const handleCopyUrl = () => {
    var text = document?.getElementById("primealphaurl")?.innerHTML;

    const tempElement = document.createElement("div");
    tempElement.innerHTML = text;
    text = tempElement.textContent;

    navigator?.clipboard?.writeText(text);
    setIsCopy(true);
    toast.success("Link Copied to clipboard.", {
      style: confimationStyles,
      duration: 1000,
    });
  };

  return (
    <div className="video-container">
      <div className="video-viewer">
        <div className="video-div">
          <iframe
            src={getEmbedUrl(selected?.link) || ""}
            title="Embedded Video"
            frameBorder="0"
            onLoad={handleLoad}
            style={{
              backgroundColor: "black",
              borderRadius: "10px 10px 10px 10px ",
            }}
            className="responsive-video-div"
          ></iframe>
        </div>
        {(visibility_type !== "shared" ||
          (visibility_type === "shared" && videos?.length > 1)) && (
          <div className="video-data">
            <div className="header">
              <div className="d-flex align-items-center">
                {visibility_type !== "shared" && (
                  <CheckboxWrapper
                    onChange={() => handleSelectAll()}
                    checked={selectedIds.length === videos.length}
                  />
                )}

                <h2 className="h2-j mb-0">Content Overview</h2>
              </div>

              {visibility_type !== "shared" && selectedIds.length > 0 && (
                <div onClick={() => handleShare()}>
                  <IconCircle
                    icon={<ShareIcon />}
                    size={40}
                    color="#ffffff"
                    border="1px solid #c0c4c9"
                  />
                </div>
              )}
            </div>
            <div style={{ maxHeight: "350px", overflowY: "auto" }}>
              {videos.map((item, index) => (
                <div
                  key={index}
                  className={`video-item ${
                    selected === item ? "active-video" : ""
                  }`}
                  onClick={() => setSelected(item)}
                >
                  {visibility_type !== "shared" && (
                    <CheckboxWrapper
                      checked={selectedIds.includes(item?.video_id)}
                      onChange={() => handleSingleSelect(item?.video_id)}
                    />
                  )}

                  <div>
                    <p className={`h4-i semi-light mb-1`}>{item.name}</p>
                    <span className="video-time-div p">
                      <i className="d-flex">
                        <VideoIcon />
                      </i>
                      &nbsp;
                      {convertSecondsToTime(item?.time)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {type !== "view" && (
        <div className="video-info mb-4">
          {selected?.description && (
            <>
              <h1 className="h1-j mb-3">Description</h1>
              <div className="quill quill-change">
                <div
                  className="ql-container ql-snow ql-no-border"
                  style={{ background: "white" }}
                >
                  <div
                    className="ql-editor blog-content"
                    style={{ padding: "0 !important" }}
                    dangerouslySetInnerHTML={{ __html: selected.description }}
                  ></div>
                </div>
              </div>
            </>
          )}
          {selected?.transcript && (
            <>
              <h1 className="h1-j mb-3 mt-3">Transcript</h1>
              <div className="quill quill-change">
                <div
                  className="ql-container ql-snow ql-no-border"
                  style={{ background: "white" }}
                >
                  <div
                    className="ql-editor blog-content"
                    style={{ padding: "0 !important" }}
                    dangerouslySetInnerHTML={{ __html: selected.transcript }}
                  ></div>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      <>
        {modalState.isModalOpen && (
          <Modal
            id={modalState.modalId}
            isActive={modalState.isModalOpen}
            title={modalState.title}
            width="642px"
            onClose={() => {
              setModalState((prev) => ({
                ...prev,
                isModalOpen: false,
              }));
              setGenerateURL(null);
            }}
            bodyStyle={{ padding: "24px" }}
          >
            <ShareLink
              id={modalState.modalId}
              name={prod_name}
              roomData={modalState.FormData}
              handleShareLinkEmail={handleShareLinkEmail}
              generateURL={generateURL}
              setGenerateURL={setGenerateURL}
              setExpiry={setExpiry}
              isCopy={isCopy}
              setIsCopy={setIsCopy}
              handleCopyUrl={handleCopyUrl}
              module="Product Videos"
              type="Video"
              given_ids={selectedIds}
              company_id={company_id}
            />
          </Modal>
        )}

        {modalState.isViewModalOpen && (
          <Modal
            id={modalState.modalId}
            isActive={modalState.isViewModalOpen}
            title={modalState.title}
            width="642px"
            onClose={() =>
              setModalState((prev) => ({
                ...prev,
                isViewModalOpen: false,
              }))
            }
          >
            <ShareLinkEmail
              generateURL={generateURL}
              isCopy={isCopy}
              handleCopyUrl={handleCopyUrl}
              setModalStateEmail={setModalState}
              setGenerateURL={setGenerateURL}
              onClose={() =>
                setModalState((prev) => ({
                  ...prev,
                  isViewModalOpen: false,
                }))
              }
              expiry={expiry}
              module="Product Videos"
              type="Video"
            />
          </Modal>
        )}
      </>
    </div>
  );
};

export default Video;
