// import Tooltip from "@mui/material/Tooltip";
// import InputLayout from "../../../../atoms/InputLayout";
// import PageCard from "../../../../atoms/PageCard";
// import InfoIcon from "../../../../Icons/info"; // Import an info icon
// import {
//   formatRange,
//   groupByProductType,
//   mapInvestmentProperties,
//   mapProductStructure,
// } from "../../People/components/InvestmentPreference";

// const InvestmentPreference = ({ data }: any) => {
//   const slotProps = {
//     popper: {
//       modifiers: [
//         {
//           name: "offset",
//           options: {
//             offset: [0, -14],
//           },
//         },
//       ],
//     },
//   };

//   const groupedData = groupByProductType(data?.investment_preference || []);

//   return (
//     <>
//       <h3 className="h3-j mb-3">
//         {/* {data?.investment_preference?.length}{" "} */}
//         {data?.investment_preference?.length <= 1
//           ? "Investment Preference"
//           : "Invetsment Preferences"}
//       </h3>

//       {Object.keys(groupedData).map((productType) => (
//         <PageCard
//           key={productType}
//           customStyles={{ backgroundColor: "#F9F9F9" }}
//           classes="p-4 mb-4 investment-preferences-container"
//         >
//           {/* Product Type */}
//           <InputLayout>
//             <div className="p-light-small">Product Type</div>
//             <div className="p-dark">{productType || "--"}</div>
//           </InputLayout>

//           {/* Product Structure */}
//           <InputLayout>
//             <div className="p-light-small">Product Structure</div>
//             <div className="p-dark">
//               {mapProductStructure(groupedData[productType]) || "--"}
//             </div>
//           </InputLayout>

//           {/* Conditionally render Product Strategy */}
//           {mapInvestmentProperties(
//             groupedData[productType],
//             "product_strategy"
//           ) && (
//             <InputLayout>
//               <div className="p-light-small">
//                 Product Strategy
//                 <Tooltip
//                   title={
//                     mapInvestmentProperties(
//                       groupedData[productType],
//                       "product_strategy",
//                       "tooltip"
//                     ) || "--"
//                   }
//                   slotProps={slotProps}
//                 >
//                   <div style={{ display: "inline-block" }} className="ms-1">
//                     <InfoIcon size="12" />
//                   </div>
//                 </Tooltip>
//               </div>
//               <div className="p-dark">
//                 {mapInvestmentProperties(
//                   groupedData[productType],
//                   "product_strategy"
//                 ) || "--"}
//               </div>
//             </InputLayout>
//           )}

//           {/* Conditionally render Product Sector */}
//           {mapInvestmentProperties(
//             groupedData[productType],
//             "product_sectors"
//           ) && (
//             <InputLayout>
//               <div className="p-light-small">
//                 Product Sector
//                 <Tooltip
//                   title={
//                     mapInvestmentProperties(
//                       groupedData[productType],
//                       "product_sectors",
//                       "tooltip"
//                     ) || "--"
//                   }
//                   slotProps={slotProps}
//                 >
//                   <div style={{ display: "inline-block" }} className="ms-1">
//                     <InfoIcon size="12" />
//                   </div>
//                 </Tooltip>
//               </div>
//               <div className="p-dark">
//                 {mapInvestmentProperties(
//                   groupedData[productType],
//                   "product_sectors"
//                 ) || "--"}
//               </div>
//             </InputLayout>
//           )}

//           {/* Conditionally render Product Geography */}
//           {mapInvestmentProperties(
//             groupedData[productType],
//             "product_geography"
//           ) && (
//             <InputLayout>
//               <div className="p-light-small">
//                 Product Geography
//                 <Tooltip
//                   title={
//                     mapInvestmentProperties(
//                       groupedData[productType],
//                       "product_geography",
//                       "tooltip"
//                     ) || "--"
//                   }
//                   slotProps={slotProps}
//                 >
//                   <div style={{ display: "inline-block" }} className="ms-1">
//                     <InfoIcon size="12" />
//                   </div>
//                 </Tooltip>
//               </div>
//               <div className="p-dark">
//                 {mapInvestmentProperties(
//                   groupedData[productType],
//                   "product_geography"
//                 ) || "--"}
//               </div>
//             </InputLayout>
//           )}

//           {/* AUM Threshold with Tooltip */}
//           {groupedData[productType]?.some(
//             (item) => item?.min_aum || item?.max_aum
//           ) && (
//             <InputLayout>
//               <div className="p-light-small">
//                 AUM Threshold
//                 <Tooltip
//                   title={
//                     groupedData[productType]
//                       .map((item) => {
//                         // Check if either min_aum or max_aum exists
//                         if (item?.min_aum || item?.max_aum) {
//                           return `${formatRange(
//                             item?.min_aum,
//                             item?.max_aum,
//                             "",
//                             true
//                           )} - ${item?.product_structure?.name || "--"}`;
//                         }
//                         return null; // Return null if both values are not present
//                       })
//                       .filter(Boolean) // Remove null values
//                       .join(", ") || "No Data Available"
//                   }
//                   slotProps={slotProps}
//                 >
//                   <div style={{ display: "inline-block" }} className="ms-1">
//                     <InfoIcon size="12" />
//                   </div>
//                 </Tooltip>
//               </div>
//               <p className="p-dark">
//                 {groupedData[productType]
//                   .map((item) =>
//                     formatRange(item?.min_aum, item?.max_aum, "", true)
//                   )
//                   .filter(Boolean) // Filter out any empty results
//                   .join(", ")}
//               </p>
//             </InputLayout>
//           )}

//           {/* Track Record with Tooltip */}
//           {groupedData[productType]?.some(
//             (item) => item?.min_track_record || item?.max_track_record
//           ) && (
//             <InputLayout>
//               <div className="p-light-small">
//                 Track Record
//                 <Tooltip
//                   title={
//                     groupedData[productType]
//                       .map((item) => {
//                         // Check if either min_track_record or max_track_record exists
//                         if (item?.min_track_record || item?.max_track_record) {
//                           return `${formatRange(
//                             item?.min_track_record,
//                             item?.max_track_record,
//                             "Years"
//                           )} - ${item?.product_structure?.name || "--"}`;
//                         }
//                         return null; // Return null if both values are not present
//                       })
//                       .filter(Boolean) // Remove null values
//                       .join(", ") || "No Data Available"
//                   }
//                   slotProps={slotProps}
//                 >
//                   <div style={{ display: "inline-block" }} className="ms-1">
//                     <InfoIcon size="12" />
//                   </div>
//                 </Tooltip>
//               </div>
//               <p className="p-dark">
//                 {groupedData[productType]
//                   .map((item) =>
//                     formatRange(
//                       item?.min_track_record,
//                       item?.max_track_record,
//                       "Years"
//                     )
//                   )
//                   .filter(Boolean) // Filter out any empty results
//                   .join(", ")}
//               </p>
//             </InputLayout>
//           )}

//           {groupedData[productType]?.some(
//             (item) => item?.min_track_record || item?.max_track_record
//           ) && (
//             <InputLayout>
//               <div className="p-light-small">
//                 Average Ticket Size
//                 <Tooltip
//                   title={
//                     groupedData[productType]
//                       .map((item) => {
//                         // Check if average_ticket_size exists
//                         if (item?.average_ticket_size) {
//                           return `$${item?.average_ticket_size} - ${
//                             item?.product_structure?.name || "Unknown Structure"
//                           }`;
//                         }
//                         return null; // Return null if average_ticket_size doesn't exist
//                       })
//                       .filter(Boolean) // Remove null values
//                       .join(", ") || "No Data Available"
//                   }
//                   slotProps={slotProps}
//                 >
//                   <div style={{ display: "inline-block" }} className="ms-1">
//                     <InfoIcon size="12" />
//                   </div>
//                 </Tooltip>
//               </div>
//               <p className="p-dark">
//                 {groupedData[productType]
//                   .map((item) => {
//                     // Only show the average ticket size if it exists
//                     return item?.average_ticket_size
//                       ? `$${item?.average_ticket_size}`
//                       : null;
//                   })
//                   .filter(Boolean) // Filter out any null values
//                   .join(", ")}
//               </p>
//             </InputLayout>
//           )}

//           {console.log(`grp`, groupedData[productType])}

//           <InputLayout>
//             <div className="p-light-small">
//               Seed
//               <Tooltip
//                 title={
//                   groupedData[productType]
//                     .map(
//                       (item) =>
//                         `${item?.seed ? "Yes" : "No"} - ${
//                           item?.product_structure?.name || "Unknown Structure"
//                         }`
//                     )
//                     .join(", ") || "No Data Available"
//                 }
//                 slotProps={slotProps}
//               >
//                 <div style={{ display: "inline-block" }} className="ms-1">
//                   <InfoIcon size="12" />
//                 </div>
//               </Tooltip>
//             </div>
//             <p className="p-dark">
//               {groupedData[productType]
//                 .map((item) => (item?.seed ? "Yes" : "No"))
//                 .join(", ")}
//             </p>
//           </InputLayout>

//           <InputLayout>
//             <div className="p-light-small">
//               Prelaunch
//               <Tooltip
//                 title={
//                   groupedData[productType]
//                     .map(
//                       (item) =>
//                         `${item?.prelaunch ? "Yes" : "No"} - ${
//                           item?.product_structure?.name || "Unknown Structure"
//                         }`
//                     )
//                     .join(", ") || "No Data Available"
//                 }
//                 slotProps={slotProps}
//               >
//                 <div style={{ display: "inline-block" }} className="ms-1">
//                   <InfoIcon size="12" />
//                 </div>
//               </Tooltip>
//             </div>
//             <p className="p-dark">
//               {groupedData[productType]
//                 .map((item) => (item?.prelaunch ? "Yes" : "No"))
//                 .join(", ")}
//             </p>
//           </InputLayout>

//           <InputLayout>
//             <div className="p-light-small">
//               First Loss Capital
//               <Tooltip
//                 title={
//                   groupedData[productType]
//                     .map(
//                       (item) =>
//                         `${item?.first_loss_capital ? "Yes" : "No"} - ${
//                           item?.product_structure?.name || "Unknown Structure"
//                         }`
//                     )
//                     .join(", ") || "No Data Available"
//                 }
//                 slotProps={slotProps}
//               >
//                 <div style={{ display: "inline-block" }} className="ms-1">
//                   <InfoIcon size="12" />
//                 </div>
//               </Tooltip>
//             </div>
//             <p className="p-dark">
//               {groupedData[productType]
//                 .map((item) => (item?.first_loss_capital ? "Yes" : "No"))
//                 .join(", ")}
//             </p>
//           </InputLayout>
//         </PageCard>
//       ))}
//     </>
//   );
// };

// export default InvestmentPreference;

import InputLayout from "../../../../atoms/InputLayout";
import PageCard from "../../../../atoms/PageCard";

const InvestmentPreference = ({ data }: any) => {
  const investmentData = [
    ...(Array.isArray(data?.investment_preference)
      ? data.investment_preference
      : []),
    ...(Array.isArray(data?.people_investment_preference)
      ? data.people_investment_preference
      : []),
  ];
  const mapProductStrategyNames = (investmentItem: any) => {
    const data =
      investmentItem?.product_startegy?.map((strategy: any) => strategy.name) ||
      [];
    const uniqueData = Array.from(new Set(data)); // Remove duplicates
    return uniqueData.join(", ");
  };

  const mapProductSectore = (investmentItem: any) => {
    const data =
      investmentItem?.product_sectors?.map((strategy: any) => strategy.name) ||
      [];
    return data.join(", ");
  };

  const mapProductGeography = (investmentItem: any) => {
    const data =
      investmentItem?.product_geography?.map(
        (strategy: any) => strategy.name
      ) || [];
    return data.join(", ");
  };

  return (
    <>
      <h3 className="h3-j mb-3">
        {/* {data?.investment_preference?.length}{" "} */}
        {investmentData?.length <= 1
          ? "Investment Preference"
          : "Invetsment Preferences"}
      </h3>

      {investmentData?.length > 0 &&
        investmentData?.map((item: any) => (
          <PageCard
            customStyles={{ backgroundColor: "#F9F9F9" }}
            classes="p-4 mb-4 investment-preferences-container"
          >
            {/* <div className="row"> */}

            <InputLayout>
              <div className="p-dark-product-company">Product Type</div>
              <div className="p-light-small-product-company">
                {item?.product_type?.[0]?.name || "--"}
              </div>
            </InputLayout>

            <InputLayout>
              <div className="p-dark-product-company">Product Structure</div>
              <div className="p-light-small-product-company">
                {item?.product_structure?.map((item) => item.name).join(",")}
              </div>
            </InputLayout>

            {item?.product_startegy?.length > 0 && (
              <InputLayout>
                <div className="p-dark-product-company">Product Strategy</div>
                <div className="p-light-small-product-company">
                  {mapProductStrategyNames(item)}
                </div>
              </InputLayout>
            )}
            {/* </div> */}

            {/* <div className="row mt-3"> */}
            {item?.product_sectors?.length > 0 && (
              <InputLayout>
                <div className="p-dark-product-company">Product Sector</div>
                <div className="p-light-small-product-company">
                  {mapProductSectore(item)}
                </div>
              </InputLayout>
            )}
            {item?.product_geography?.length > 0 && (
              <InputLayout>
                <div className="p-dark-product-company">Product Geography</div>
                <div className="p-light-small-product-company">
                  {mapProductGeography(item)}
                </div>
              </InputLayout>
            )}
            {item?.min_aum || item?.max_aum ? (
              <InputLayout>
                <div className="p-dark-product-company">AUM Threshold</div>
                <p className="p-light-small-product-company">
                  {item?.min_aum}
                  {item?.min_aum !== null && item?.max_aum !== null && (
                    <>&nbsp;-&nbsp;</>
                  )}
                  {item?.max_aum !== null && item?.max_aum}
                  {(item?.min_aum || item?.max_aum) && " $"}
                </p>
              </InputLayout>
            ) : null}
            {/* </div> */}

            {
              item?.min_track_record || item?.max_track_record ? (
                // <div className="row mt-2">
                // <>
                <InputLayout>
                  <div className="p-dark-product-company">
                    {" "}
                    Track Length Record
                  </div>
                  <p className="p-light-small-product-company">
                    {item?.min_track_record}
                    {item?.min_track_record !== null &&
                      item?.max_track_record !== null &&
                      " - "}
                    {item?.max_track_record ?? ""}
                
                    {(item?.min_track_record || item?.max_track_record) &&
                      " years"}
                  </p>
                </InputLayout>
              ) : null

              // {/* <InputLayout></InputLayout>
              // <InputLayout></InputLayout> */}
              // </div>
            }

            {item?.average_ticket_size > 0 && (
              <InputLayout>
                <div className="p-dark-product-company">
                  {" "}
                  Average Ticket Size
                </div>
                <p className="p-light-small-product-company">
                  ${item?.average_ticket_size} M
                </p>
              </InputLayout>
            )}

            <InputLayout>
              <div className="p-dark-product-company"> Seed</div>
              <p className="p-light-small-product-company">
                {/* ${item?.average_ticket_size} M */}
                {item?.seed ? "Yes" : "No"}
              </p>
            </InputLayout>

            <InputLayout>
              <div className="p-dark-product-company"> Prelaunch</div>
              <p className="p-light-small-product-company">
                {item?.prelaunch ? "Yes" : "No"}
              </p>
            </InputLayout>

            <InputLayout>
              <div className="p-dark-product-company"> First Loss Capital</div>
              <p className="p-light-small-product-company">
                {item?.first_loss_capital ? "Yes" : "No"}
              </p>
            </InputLayout>
          </PageCard>
        ))}
    </>
  );
};

export default InvestmentPreference;
