import React, { CSSProperties, ReactNode } from 'react';
import styles from './page_card.module.scss';

interface PageCardProps {
  children: ReactNode;
  classes?: string;
  customStyles?: CSSProperties;  // Changed from 'styles' to 'customStyles'
  id?: string
}

const PageCard: React.FC<PageCardProps> = ({ children, classes = '', customStyles = {} , id}) => {
  return (
    <div className={`${styles.page_card} ${classes}`} style={customStyles} id={id}>
      {children}
    </div>
  );
};

export default PageCard;
