//to be used in Product Database as well

import Search from "../../../../../../atoms/Search";
import TypeDiv from "../../../../../../atoms/TypeDiv";
import style from "../Company/styles.module.scss";

import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSubscription } from "../../../../../../../SubscriptionContext";
import useScreenWidth from "../../../../../../../hooks/useScreenwidth";
import SortFunctionality from "../../../../../../organisms/SortFunctionality";
import ContentSharingPhoneShimmer from "../../../../ContentSharing/Shimmer/ContentSharingPhones";
import ShimmerDatabase from "../../../../Products/Shimmer/shimmerDatabase";

import { useNavigate } from "react-router-dom";
import { areObjectsEqual } from "../../../../../../../helpers/areObjectsEqual";
import { TeamType } from "../../../../../../../helpers/staticData";
import { getData } from "../../../../../../../services";
import { getPeopleData } from "../../../../../../../services/investorService";
import { setLoading } from "../../../../../../../store/slices/loaderSlice";
import ArrowDown from "../../../../../../Icons/ArrowDown";
import RightUpArrow from "../../../../../../Icons/RightUpArrow";
import PeopleProfileCard from "../../../../../../atoms/PeopleProfileCard";
import ResetBtn from "../../../../../../atoms/ResetBtn/ResetBtn";
import NoContentCard from "../../../../../../molecules/NoContentCard";
import Pagination from "../../../../../../molecules/Pagination";
import SaveComponent from "../../../../../../molecules/SaveComponent";
import SaveAll from "../../../../../../molecules/SaveComponent/SaveAll";
import MultiselectFilter from "../../../../../../organisms/MultiselectFilter";
import DireactDeal from "../../../../Products/NewProductDatabase/NewFilter/DirectDeal";
import Funds from "../../../../Products/NewProductDatabase/NewFilter/Funds";
import CreatedInvestorFilter from "../../../Filter/CreatedInvestorFilter";
import UpdateInvestorFilter from "../../../Filter/UpdatedInvestorFilter";
import Location from "../../NewFilter/Location";
import Preferances from "../../NewFilter/Preferances";

const People = ({ people_type = "investor" }) => {
  const { isSubscriptionExpired, handleUserClick } = useSubscription();

  const [cookies] = useCookies(["t"]);
  const screenwidth = useScreenWidth();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sort_by, setSort_By] = useState(null);

  const [quickSearchData, setQuickSearchData] = useState(location.state);
  const [searchvalue, setSearchvalue] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [InvestorList, setInvestoreList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [filterOpenCreated, setFilterOpenCreated] = useState(false);
  const [filterOpenUpdated, setFilterOpenUpdated] = useState(false);

  const [aum, setAum] = useState({ min: null, max: null });
  const [direactDealFilter, setDireactDealsFilter] = useState({});
  const [fundingGoal, setFundingGoal] = useState({ min: null, max: null });

  const [tagData, setTagData] = useState([]);
  const [tagFilter, setTagFilter] = useState();
  const [filterOpenTags, setFilterOpenTags] = useState(false);
  const [createdFilter, setCreatedFilter] = useState();
  const [updatedFilter, setUpdatedFilter] = useState();
  const [teamFilter, setTeamFilter] = useState(false);
  const [filterOpenTeamType, setFilterOpenTeamType] = useState(false);

  const [filters, setFilters] = useState({});
  const [locationFilter, setLocationFilter] = useState({});
  const [investorType, setInvestorType] = useState();
  const [filterOpenInvestorType, setFilterOpenInvestorType] = useState(false);
  const [investorTypeFilter, setInvestorTypeFilter] = useState([]);
  const [refetch, setRefetch] = useState(false);
  // const [aum, setAum] = useState({ min: null, max: null });

  const [resetAll, setResetAll] = useState(false);
  const [dropdownPositions, setDropdownPositions] = useState({
    tags: {},
    created: {},
    updated: {},
    more: {},
    teams: {},
  });

  const fetchTags = async () => {
    const res = await getData({
      endpoint: "Search/getPeopleTags",
      params: {
        type: people_type === "investor" ? "Investor" : "Manager/Sponsor",
      },
      token: cookies.t,
    });
    let result = [];
    res.forEach((item) => {
      if (item !== null) {
        result.push({ value: item, label: item });
      }
    });
    setTagData(result);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const handleDirectType = (direactDealFilter) => {
    const tempDirect =
      direactDealFilter["Direct Deal Type"]?.map((item) => item?.value) || [];

    return [...tempDirect];
  };

  const handleDirectStrategy = (direactDealFilter) => {
    const tempSPV =
      direactDealFilter["Direct Deal Strategy"]?.map((item) => item?.value) ||
      [];

    return [...tempSPV];
  };

  // get all list data ---------------------------
  useEffect(() => {
    dispatch(setLoading(true));

    const getInvestorList = async () => {
      try {
        const res = await getPeopleData({
          type: people_type,
          token: cookies.t,
          page: currentPage,
          aum: aum,
          page_limit: itemsPerPage,
          search_query: searchvalue,
          investorType: investorTypeFilter,
          product_category: filters?.["Product Category"]?.map(
            (proCategory) => proCategory?.value
          ),
          product_structure: filters?.["Product Structure"]?.map(
            (proCategory) => proCategory?.value
          ),
          product_type:
            direactDealFilter["Direct Deal Type"] &&
            filters?.["Product Type"]?.length > 0
              ? [
                  ...handleDirectType(direactDealFilter),
                  ...filters["Product Type"].map((category) => category?.value),
                ]
              : direactDealFilter["Direct Deal Type"]
              ? handleDirectType(direactDealFilter)
              : filters?.["Product Type"]?.map((category) => category?.value),

          product_strategy:
            direactDealFilter["Direct Deal Strategy"] &&
            filters?.["Product Strategy"]?.length > 0
              ? [
                  ...handleDirectStrategy(direactDealFilter),
                  ...filters["Product Strategy"].map(
                    (category) => category?.value
                  ),
                ]
              : direactDealFilter["Direct Deal Strategy"]
              ? handleDirectStrategy(direactDealFilter)
              : filters?.["Product Strategy"]?.map(
                  (category) => category?.value
                ),
          sector:
            filters?.Sector &&
            filters?.["Sector"]?.map((sector) => sector.value),
          geography:
            filters?.Geography &&
            filters?.["Geography"]?.map((geography) => geography.value),
          teams: teamFilter,
          tags: tagFilter,
          updatedFilter: updatedFilter,
          createdFilter: createdFilter,
          country:
            locationFilter?.Country &&
            locationFilter?.["Country"]?.map((country) => country.label),
          state:
            locationFilter?.State &&
            locationFilter?.["State"]?.map((state) => state.label),
          city:
            locationFilter?.City &&
            locationFilter?.["City"]?.map((city) => city.label),
          fundingFilter: fundingGoal,
          sort_by: sort_by,
        });

        if (res) {
          setShowLoader(true);
        }
        setInvestoreList(res);
      } catch (exp) {
        console.log("investor-people", exp);
      }
    };
    getInvestorList();
  }, [
    aum,
    fundingGoal,
    searchvalue,
    currentPage,
    itemsPerPage,
    fundingGoal,
    direactDealFilter,
    filters,
    tagFilter,
    updatedFilter,
    createdFilter,
    locationFilter,
    investorTypeFilter,
    teamFilter,
    sort_by,
    refetch,
  ]);

  const handleSearch = (e) => {
    setSearchvalue(e);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const fetchInvestorData = async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "Taxonomy/getInvestorTypeList",
      params: {
        status: true,
      },
      token: cookies.t,
    });
    dispatch(setLoading(false));
    setInvestorType(res);
  };

  useEffect(() => {
    fetchInvestorData();
  }, []);

  const handleFilterChange = (values) => {
    if (Object.keys(values)?.length > 0) {
      const modifiedData = Object.keys(values).reduce((acc, key) => {
        const newKey = key;
        acc[newKey] = values[key];
        return acc;
      }, {});

      // Check if the new modifiedData is different from the existing filters
      if (!areObjectsEqual(modifiedData, filters)) {
        if (currentPage !== 1) setCurrentPage(1);
        setFilters(modifiedData);
      }
    } else {
      setFilters({});
    }
  };

  const totalItems = InvestorList?.total_record;

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const handleResetAll = () => {
    setResetAll(true);
  };

  const handleView = (e, item) => {
    e.preventDefault();
    window.open(`/dashboard/company/${item}`, "_blank");
  };

  const handlePeopleView = (e, item) => {
    e.preventDefault();
    window.open(`/dashboard/people/${item}`, "_blank");
  };

  const updateDropdownPositions = () => {
    const tagsDropdown = document.getElementById("tagsDropdown");
    const tagsDropdownRect = tagsDropdown?.getBoundingClientRect();

    const teamsDropdown = document.getElementById("teamsDropdown");
    const teamsDropdownRect = teamsDropdown?.getBoundingClientRect();

    const createdDropdown = document.getElementById("createdDropdown");
    const createdDropdownRect = createdDropdown?.getBoundingClientRect();

    const updatedDropdown = document.getElementById("updatedDropdown");
    const updatedDropdownnRect = updatedDropdown?.getBoundingClientRect();

    const moreFiltersDrop = document.getElementById("more_Filters");
    const moreFiltersRect = moreFiltersDrop?.getBoundingClientRect();

    setDropdownPositions({
      teams: {
        left: teamsDropdownRect?.left,
      },
      tags: {
        left: tagsDropdownRect?.left,
      },
      created: {
        left: createdDropdownRect?.left,
      },
      updated: {
        left: updatedDropdownnRect?.left,
      },
      more: {
        left: moreFiltersRect?.left,
      },
    });
  };

  useEffect(() => {
    updateDropdownPositions();
  }, [screenwidth]);

  const shouldShowSaveAll = () => {
    const hasFilters = filters && Object.keys(filters).length > 0;

    const hasLocationFilter =
      locationFilter && Object.keys(locationFilter).length > 0;

    const hasTagFilter = tagFilter && tagFilter.length > 0;

    const hasCreatedFilter =
      createdFilter &&
      typeof createdFilter === "object" &&
      !Array.isArray(createdFilter) &&
      Object.keys(createdFilter).length > 0;

    const hasUpdatedFilter =
      updatedFilter &&
      typeof updatedFilter === "object" &&
      !Array.isArray(updatedFilter) &&
      Object.keys(updatedFilter).length > 0;

    const hasAum = aum && (aum.min || aum.max);

    const hasFundingGoal = fundingGoal && (fundingGoal.min || fundingGoal.max);

    const hasTeam = teamFilter?.length > 0;

    const hasPreference =
      investorTypeFilter &&
      typeof investorTypeFilter === "object" &&
      !Array.isArray(investorTypeFilter) &&
      Object.keys(investorTypeFilter).length > 0;

    const hasInvestorType = investorTypeFilter?.length > 0;

    const hasDirectDeal =
      direactDealFilter && Object.keys(direactDealFilter).length > 0;

    // Final condition check
    const result =
      hasFilters ||
      searchvalue ||
      hasLocationFilter ||
      hasTagFilter ||
      hasCreatedFilter ||
      hasUpdatedFilter ||
      hasAum ||
      hasFundingGoal ||
      hasTeam ||
      hasPreference ||
      hasInvestorType ||
      hasDirectDeal;

    return result;
  };

  return (
    <div className={style.mainCompanyDiv}>
      <div className="d-flex justify-content-between">
        <div className="h1-i">People</div>
        {/* <div className={style.search_header}></div> */}
        <Search
          variant="search_md"
          onSearchEmit={handleSearch}
          className={style.search_responsive_btn}
        />
      </div>

      <div className="d-flex gap-2  ms-2 flex-wrap ms-2"></div>
      <div className={style.companiesHeader}>
        <div className="d-flex gap-2 flex-wrap mt-3">
          <SortFunctionality
            reset={resetAll}
            handleSort={(sort) => {
              setSort_By(sort);
            }}
          />
          {people_type === "investor" && (
            <>
              <MultiselectFilter
                title="Investor Type"
                icon={<ArrowDown />}
                data={investorType}
                handleFilter={(filter) => {
                  setInvestorTypeFilter(filter);
                  setResetAll(false);
                }}
                open={filterOpenInvestorType}
                setOpen={setFilterOpenInvestorType}
                id="InvestorTypeId"
                dropdownPosition={dropdownPositions.investor}
                sizeType="small"
                reset={resetAll}
                // quickSearchData={quickSearchData}
                // setQuickSearchData={setQuickSearchData}
              />
              {/* <AumFilter
                handleAum={(data) => {
                  setAum(data);
                }}
                reset={resetAll}
                id="aumDropdown"
                dropdownPosition={dropdownPositions.aum}
              /> */}
            </>
          )}

          {people_type !== "investor" && (
            <>
              <Funds
                onFilterChange={(values) => {
                  setFilters(values);
                }}
                type="product"
                headingtext="Funds"
                headingMobile={false} // Set to true if you want to hide the "Filters" button text on mobile
                disableKeysUntilCondition={true} // Pass the disableKeysUntilCondition object
                reset={resetAll}
                id="more_Filters"
                isDateRange
                dateRangeCount={1}
                dateRangeNames={["AUM($mm)"]}
                handleAum={(data) => {
                  setAum(data);
                }}
                QuickSearch={quickSearchData}
                setQuickSearchData={setQuickSearchData}
                // className={style.reset_responsive_Mobile_btn}
              />
              <DireactDeal
                type="product"
                headingtext="Direct Deals"
                id="direact_deals"
                headingMobile={false} // Set to true if you want to hide the "Filters" button text on mobile
                disableKeysUntilCondition={true}
                isDateRange
                dateRangeCount={1}
                dateRangeNames={["Funding Goal($mm)"]}
                onFilterChange={(values) => {
                  setDireactDealsFilter(values);
                }}
                handleAum={(data) => {
                  setFundingGoal(data);
                }}
                reset={resetAll}
              />
            </>
          )}

          <Location
            onFilterChange={(values) => {
              setLocationFilter(values);
            }}
            type="product"
            headingtext="Location"
            headingMobile={false}
            disableKeysUntilCondition={true}
            reset={resetAll}
            id={"location_filter"}
            tabresponsive={true}
          />
          {people_type === "investor" && (
            <>
              <Preferances
                onFilterChange={(values) => {
                  setFilters(values);
                }}
                type="product"
                headingtext="Preference"
                headingMobile={false} // Set to true if you want to hide the "Filters" button text on mobile
                disableKeysUntilCondition={true} // Pass the disableKeysUntilCondition object
                reset={resetAll}
                id="more_Filters"
                tabresponsive
                handleAum={() => {}}
                // className={style.reset_responsive_Mobile_btn}
              />
            </>
          )}

          <CreatedInvestorFilter
            handleFilter={(data) => setCreatedFilter(data)}
            filterOpenCreated={filterOpenCreated}
            setFilterOpenCreated={setFilterOpenCreated}
            id="createdDropdown"
            dropdownPosition={dropdownPositions.created}
            type="small"
            reset={resetAll}
          />
          <UpdateInvestorFilter
            handleFilter={(data) => setUpdatedFilter(data)}
            filterOpenUpdated={filterOpenUpdated}
            setFilterOpenUpdated={setFilterOpenUpdated}
            id="updatedDropdown"
            dropdownPosition={dropdownPositions.updated}
            type="small"
            reset={resetAll}
          />

          <MultiselectFilter
            title="Team Type"
            icon={<ArrowDown />}
            data={TeamType}
            handleFilter={(filter) => {
              setTeamFilter(filter);
              setResetAll(false);
            }}
            open={filterOpenTeamType}
            setOpen={setFilterOpenTeamType}
            setQuickSearchData={setQuickSearchData}
            id="teamsDropdown"
            dropdownPosition={dropdownPositions.teams}
            sizeType="small"
            reset={resetAll}
            tabresponsive={true}
          />

          <MultiselectFilter
            title="Tags"
            icon={<ArrowDown />}
            data={tagData}
            handleFilter={(filter) => {
              setTagFilter(filter);
              setResetAll(false);
            }}
            open={filterOpenTags}
            setOpen={setFilterOpenTags}
            setQuickSearchData={setQuickSearchData}
            id="tagsDropdown"
            dropdownPosition={dropdownPositions.tags}
            sizeType="small"
            reset={resetAll}
            tabresponsive={true}
            info={true}
            information={"Based on added Tags"}
          />
          {/* <FilterComponent
            onFilterChange={handleFilterChange}
            type="investorPeople"
            headingMobile={false} // Set to true if you want to hide the "Filters" button text on mobile
            reset={resetAll}
            id="moreFilters"
            dropdownPosition={dropdownPositions.more}
            className={style.reset_responsive_btn}
          /> */}

          <ResetBtn onReset={() => handleResetAll()} type="small" />

          {shouldShowSaveAll() && InvestorList?.data?.length > 0 && (
            <SaveAll
              type={"People"}
              saveType={
                people_type === "investor" ? "InvestorPeople" : "ManagerPeople"
              }
              filters={filters}
              search={searchvalue}
              setRefetch={setRefetch}
              locationFilter={locationFilter}
              tagFilter={tagFilter}
              createdFilter={createdFilter}
              updatedFilter={updatedFilter}
              aum={aum}
              fundingGoal={fundingGoal}
              teamFilter={teamFilter}
              investor_types={investorTypeFilter}
              directDealTypeFilter={handleDirectType(direactDealFilter)}
              directDealStrategyFilter={handleDirectStrategy(direactDealFilter)}
            />
          )}
        </div>
      </div>
      {!showLoader ? (
        <>
          {screenwidth > 900 ? (
            <ShimmerDatabase />
          ) : (
            <ContentSharingPhoneShimmer type="Database" />
          )}
        </>
      ) : (
        <>
          <div className={style.investorMapWrap}>
            {InvestorList?.data?.length > 0 ? (
              <>
                {InvestorList?.data
                  ?.concat(
                    isSubscriptionExpired &&
                      InvestorList?.total_record > 10 &&
                      people_type === "manager"
                      ? [InvestorList?.data?.[InvestorList?.data?.length - 1]]
                      : []
                  )
                  .map((item, index) => {
                    const isLastItem = index === InvestorList?.data?.length;

                    return (
                      <div
                        className={`${style.investorDataWrap} ${
                          isLastItem &&
                          isSubscriptionExpired &&
                          people_type === "manager"
                            ? style.blurredItemContainer
                            : ""
                        }`}
                      >
                        {isLastItem && isSubscriptionExpired && (
                          <div className={style.subscriptionOverlay}>
                            <div className={style.subscriptionMessage}>
                              You have restricted access to this module.{" "}
                              <span
                                className="text-decoration-underline cursor-pointer"
                                onClick={(e) => handleUserClick(e)}
                              >
                                Click here
                              </span>{" "}
                              to view more information.
                            </div>
                          </div>
                        )}
                        <div
                          className={`${style.investor_inner_datawrap} ${
                            isLastItem && isSubscriptionExpired
                              ? style.blur
                              : ""
                          }`}
                        >
                          <div className={style.investorInfoWrap}>
                            <PeopleProfileCard
                              profile={item?.profile_photo}
                              size="80px"
                              borderRadius="10%"
                              name={item.name}
                            />
                            <div>
                              <div
                                className={`d-flex align-items-center gap-3 mb-1 ${style.Product_database_heading_container}`}
                              >
                                <h6
                                  className={style.companyName}
                                  onClick={(e) => {
                                    isSubscriptionExpired
                                      ? handleUserClick(e)
                                      : handlePeopleView(e, item?.people_id);
                                  }}
                                >
                                  {item?.name}
                                </h6>
                              </div>
                              {/* <div className={style.companyType}>{item?.type}</div>
                  <div className={style.companyType}>{item?.email}</div> */}
                              {/* {item?.address?.length > 0 && ( */}

                              <div
                                className={`${style.companyType} ${style.mbres_none} light`}
                              >
                                {item?.city ? `${item.city}, ` : ""}
                                {item?.state ? `${item.state}, ` : ""}
                                {item?.country ? `${item.country} ` : ""}
                              </div>

                              <div
                                className="d-flex gap-1 cursor-pointer"
                                onClick={(e) =>
                                  isSubscriptionExpired
                                    ? handleUserClick(e)
                                    : handleView(e, item?.company_id)
                                }
                              >
                                <div
                                  className={`${style.companyType_dark} ${style.mbres_none} light mt-0`}
                                >
                                  {item?.company_name}
                                </div>
                                <RightUpArrow />
                              </div>

                              {/* <div
                              className={`${style.companyType} ${style.mbres_none} light pb-1`}
                            >
                              {item?.email}
                            </div> */}

                              {/* )} */}
                              <div
                                className={`${style.companyType} ${style.mbres_none}`}
                              >
                                <div className="d-flex align-items-center light">
                                  {/* Added On :{" "}
                                {moment
                                  .utc(item?.created_date)
                                  .format("MMM D, YYYY")} */}
                                  {/* <div className="dot"></div> */}
                                  Updated On{" "}
                                  {moment
                                    .utc(item?.updated_date)
                                    .format("MMM D, YYYY")}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={style.database_none_wrapper}>
                            <>
                              <TypeDiv
                                data={item?.type}
                                classes={style.typediv_productDatabse}
                                color={
                                  item?.type === "Investor/Manager/Sponsor"
                                    ? "#B5E4CA"
                                    : item?.type === "Investor"
                                    ? "#FFBC99"
                                    : "#CABDFF"
                                }
                              />
                              {item?.address?.length > 0 && (
                                <div className={`${style.companyType}`}>
                                  {item?.address?.[0]?.city +
                                    ", " +
                                    item?.address?.[0]?.state_region +
                                    ", " +
                                    item?.address?.[0]?.country}
                                </div>
                              )}
                              <div className={`${style.companyType}`}>
                                <div className="">
                                  Added On :{" "}
                                  {moment
                                    .utc(item?.created_date)
                                    .format("MMM D, YYYY")}
                                </div>
                              </div>
                              <div className={`${style.companyType}`}>
                                Last Update:{" "}
                                {moment
                                  .utc(item?.updated_date)
                                  .format("MMM D, YYYY")}
                              </div>
                            </>
                          </div>
                        </div>
                        <div
                          className={`${style.investorButtons} ${
                            isLastItem && isSubscriptionExpired
                              ? style.blur
                              : ""
                          }`}
                        >
                          <SaveComponent
                            type="people"
                            key={item?.people_id}
                            id={item?.people_id}
                            saved_id={item?.saved_list}
                            btnType="btn-outline-dark"
                          />

                          <div
                            className="btn btn-outline-dark"
                            onClick={(e) => {
                              isSubscriptionExpired
                                ? handleUserClick(e)
                                : handlePeopleView(e, item?.people_id);
                            }}
                          >
                            View
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </>
            ) : (
              <>
                {/* {searchvalue ||
                tagFilter?.length > 0 ||
                locationData?.length > 0 ||
                investorTypeFilter?.length > 0 ||
                aum.max != null ||
                aum.min != null ? (
                  <NoContentCard
                    title="Sorry! No Result Found"
                    type="Search"
                    subtitle="We couldn't find investors that match your search."
                  />
                ) : ( */}
                <NoContentCard title="No Data Found" />
                {/* )} */}
              </>
            )}
          </div>

          {/* Pagination  */}
          {InvestorList?.total_record >= 10 && !isSubscriptionExpired && (
            <div className="mt-2 ms-sm-4">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default People;
