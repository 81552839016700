import { getData } from ".";

export const getProductsData = async ({
  token,
  search,
  people_search,
  currentPage,
  itemsPerPage,
  investorType,
  locationData,
  interestData,
  categoryData,
  structureData,
  sector,
  geography,
  productInterest,
  targetFilter,
  fundingFilter,
  tags,
  aum,
  country,
  state,
  city,
  updatedFilter,
  createdFilter,
  created_end_date,
  created_start_date,
  updated_end_date,
  updated_start_date,
  after_date,
  before_date,
  type,
  is_people,
  product_strategy,
  product_type,
  sort_by,
}) => {
  if (type === "people") {
    locationData = locationData && JSON.parse(locationData);
    interestData = interestData && JSON.parse(interestData);
    createdFilter = createdFilter && JSON.parse(createdFilter);
    updatedFilter = updatedFilter && JSON.parse(updatedFilter);
    productInterest = productInterest && JSON.parse(productInterest);
    targetFilter = targetFilter && JSON.parse(targetFilter);
    aum = aum && JSON.parse(aum);
    fundingFilter = fundingFilter && JSON.parse(fundingFilter);
  }
  const pagination = {
    page: currentPage,
    people_search: people_search,
    page_limit: itemsPerPage,
    search_query: search,
    investor_types: investorType,
    min_aum: aum?.min,
    max_aum: aum?.max,
    country: JSON.stringify(country) || locationData?.country,
    state: JSON.stringify(state) || JSON.stringify(locationData?.state),
    city: JSON.stringify(city) || JSON.stringify(locationData?.city),
    // product_type:
    //   productInterest?.type?.length > 0
    //     ? Array.isArray(productInterest?.type)
    //       ? JSON.stringify(productInterest?.type)
    //       : JSON.stringify([productInterest?.type])
    //     : null,
    product_type: JSON.stringify(product_type),
    // product_strategy: JSON.stringify(productInterest?.strategy),
    product_strategy: JSON.stringify(product_strategy),
    sector: sector?.length > 0 ? JSON.stringify(sector) : null,
    geography: geography?.length > 0 ? JSON.stringify(geography) : null,
    product_structure:
      structureData?.length > 0 ? JSON.stringify(structureData) : null,
    after_date,
    before_date,
    min_funding: fundingFilter?.min,
    max_funding: fundingFilter?.max,
    tags: type === "people" ? tags : JSON.stringify(tags),
    created_start_date,
    created_end_date,
    updated_start_date,
    updated_end_date,
    is_people: is_people || false,
    sort_by: sort_by,
  };
  try {
    const response = await getData({
      endpoint: "Search/searchProduct",
      params: pagination,
      token: token,
    });
    return response;
  } catch (exp) {}
};

export const getManagerPeopleData = async ({
  type,
  token,
  page,
  page_limit,
  search_query,
  product_type,
  sector,
  geography,
  tags,
  updatedFilter,
  createdFilter,
}) => {
  const params = {
    page,
    page_limit,
    search_query,
    product_type: JSON.stringify(product_type),
    created_start_date: createdFilter?.created_start_date,
    created_end_date: createdFilter?.created_end_date,
    updated_start_date: updatedFilter?.updated_start_date,
    updated_end_date: updatedFilter?.updated_end_date,
    sector: JSON.stringify(sector),
    geography: JSON.stringify(geography),
    tags: tags?.length > 0 ? JSON.stringify(tags) : null,
  };

  try {
    const response = await getData({
      endpoint: "Search/productPeopleSearch",
      params: params,
      token: token,
    });
    return response;
  } catch (exp) {
    console.log("Product Error");
  }
};

export const fetchThumbnail = async ({ video_id, token }) => {
  const res = await getData({
    endpoint: "Wrapper/video-info",
    params: { videoId: video_id },
    token: token,
  });

  return res;
};
