import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthenticated } from "../hooks/useAuthenticated";

// Create a context for subscription status
const SubscriptionContext = createContext();

// Custom hook to use the SubscriptionContext
export const useSubscription = () => useContext(SubscriptionContext);

// SubscriptionProvider component to wrap around the App
export const SubscriptionProvider = ({ children }) => {
  const [isSubscriptionExpired, setSubscriptionExpired] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [top, setTop] = useState("0px");

  const isAuthenticated = useAuthenticated();

  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  // Simulate checking subscription status from an API or server
  useEffect(() => {
    const checkSubscription = () => {
      const trial_data_string = localStorage.getItem("trial_info");
      let trial_data;

      if (trial_data_string !== null) {
        trial_data = JSON.parse(trial_data_string);
      }

      // Validate userInfo
      const isUserInfoValid =
        userInfo &&
        typeof userInfo === "object" &&
        userInfo.hasOwnProperty("subscription_enable") &&
        userInfo.hasOwnProperty("subs_end_date");

      // Define the subscription check logic
      const isSubscriptionExpired = () => {
        if (isUserInfoValid) {
          // Prioritize userInfo
          return (
            userInfo.subscription_enable === false ||
            moment(userInfo.subs_end_date)
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .isBefore(moment())
          );
        }

        // Fall back to trial_data if userInfo is not valid
        return (
          !trial_data?.subscription_enable ||
          moment(trial_data?.subs_end_date)
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .isBefore(moment())
        );
      };

      if (isSubscriptionExpired()) {
        setSubscriptionExpired(true);
        setIsShow(false);
      } else {
        setSubscriptionExpired(false);
        setIsShow(false);
      }
    };

    if (isAuthenticated) {
      checkSubscription();
    }

    // both isAuthenticated ND userInfo are required in the dependency to get the updated permissions
  }, [isAuthenticated, userInfo]);

  // Function to handle user clicks
  const handleUserClick = (e) => {
    if (isSubscriptionExpired) {
      e.preventDefault();
      e.stopPropagation();
      setIsShow(true);
    }
  };

  return (
    <SubscriptionContext.Provider
      value={{
        isSubscriptionExpired,
        handleUserClick,
        setIsShow,
        isShow,
        top,
        setTop,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
