import { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useSubscription } from "../../../../../../SubscriptionContext";
import UserProfileCardRoad from "../../../../../molecules/UserProfileCard/userCardRoadMap";
import style from "./index.module.scss";

const InvestorPeople = ({ company_id, data, type }) => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const [investmentData, setInvestmentData] = useState();

  const { handleUserClick, isSubscriptionExpired } = useSubscription();

  return (
    <div className={style.peopleWrap}>
      {data.length > 0 &&
        data?.map((item) => {
          return (
            <div>
              <div className={style.company_team}>
                <UserProfileCardRoad
                  key={item.people_id}
                  profileName={item.first_name}
                  profilePosition={item.title}
                  onClick={(e) => {
                    type === "roadshow" && isSubscriptionExpired
                      ? handleUserClick(e)
                      : window.open(
                          `/dashboard/people/${item.people_id}`,
                          "_blank"
                        );
                  }}
                  profilePicture={item.profile_photo}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default InvestorPeople;
