import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Default from "../../../../assets/images/default.png";
import { formatNumber } from "../../../../helpers/numberFormatter";
import { getData } from "../../../../services";
import { loaderData, setLoading } from "../../../../store/slices/loaderSlice";
import Heading from "../../../atoms/Heading";
import ComapanyStats, {
  StatsConatiner,
} from "../../../templates/InfoPage/CompanyStats/ComapanyStats";
import HeroSection from "../../../templates/InfoPage/HeroSection/HeroSection";
import ImageContent, {
  ImageContentContainer,
  ImageContentHeading,
} from "../../../templates/InfoPage/ImageContent/ImageContent";
import LatestAdded, {
  AddLatest,
} from "../../../templates/InfoPage/LastAdded/LatestAdded";
import PortfolioCard, {
  PortCards,
} from "../../../templates/InfoPage/PortfolioCards/portfolioCard";
import QuickSearch from "../../../templates/InfoPage/QuickSearch/QuickSearch";
import ShimmerProduct from "../Products/Shimmer/shimmer";
import "./investor.scss";
import Diversification from "./requirnment/diversification.png";
import InvestmentHub from "./requirnment/investmentsHub.png";

const InvestorHome = () => {
  const dispatch = useDispatch();
  const [cookies] = useCookies([
    "t",
    "role",
    "investor_access",
    "resources_access",
  ]);

  const userInfo = useSelector((state) => state.peopleData?.peopleData);
  const loadingDataValue = useSelector(loaderData);
  const navigate = useNavigate();

  const [subscribed, setSubscribed] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [investorTypeData, setInvestorTypeData] = useState([]);
  const [companyStatsAndLatest, setCompanyStatsAndLatest] = useState({});

  const getResourceDataStats = useCallback(async () => {
    try {
      dispatch(setLoading(true));
      const statData = await getData({
        endpoint: "Dashboard/getInvestorDashboardData",
        token: cookies.t,
      });
      dispatch(setLoading(false));
      setCompanyStatsAndLatest(statData);
    } catch (error) {
      console.error(error);
    }
  }, [cookies.t, getData, setCompanyStatsAndLatest]);

  const getInvestorTypeData = useCallback(async () => {
    try {
      const investorTypeList = await getData({
        params: {
          status: true,
        },
        endpoint: "Taxonomy/getInvestorTypeList",
        token: cookies.t,
      });
      setInvestorTypeData(investorTypeList);
    } catch (exp) {
      console.log("Error", exp);
    }
  }, [cookies.t]);

  useEffect(() => {
    getResourceDataStats();
    getInvestorTypeData();
  }, [getResourceDataStats, getInvestorTypeData]);

  const handleButtonClick = () => {
    setRedirect(true);
  };

  const handleInvestorDb = () => {
    navigate("/dashboard/investors/investordatabase");
  };

  const handleResouces = () => {
    navigate("/dashboard/resources");
  };

  useEffect(() => {
    if (redirect) {
      window.location.href = userInfo?.investor_learn_more_link;
    }
  }, [redirect]);

  const handleLock = () => {
    window.location.href = userInfo?.investor_learn_more_link;
  };
  function capitalizeFirstLetterEachWord(str) {
    return str.replace(/\b\w/g, (match) => match.toUpperCase());
  }

  const handleQuickSearch = (data) => {
    if (userInfo?.has_master_access || userInfo?.investor_database) {
      navigate("/dashboard/investors/investordatabase", {
        state: data,
      });
    } else {
      window.location.assign(userInfo?.investor_learn_more_link);
    }
  };

  return (
    <div id="Investor-Container">
      {loadingDataValue ? (
        <ShimmerProduct />
      ) : (
        <div className="Investor-DataBase-Container">
          <Heading
            type="main"
            title="Investors"
            classes="investor-heading-conatiner"
          />
          <HeroSection
            ImagePic={companyStatsAndLatest?.cover_photo || Default}
            Heading={["Investor Database & Marketing Best Practices"]}
            Button={
              userInfo?.has_master_access || userInfo?.investor_database
                ? "Search Investor Database"
                : "Learn More"
            }
            onClick={
              userInfo?.has_master_access || userInfo?.investor_database
                ? handleInvestorDb
                : handleButtonClick
            }
            isButton2Visible={
              userInfo?.has_master_access || userInfo?.investor_database
            }
            Button2={
              userInfo?.has_master_access || userInfo?.investor_database
                ? "Marketing Best Practices"
                : "Marketing Best Practices"
            }
            onClick2={
              userInfo?.has_master_access || userInfo?.investor_database
                ? handleResouces
                : handleButtonClick
            }
          />

          <ComapanyStats>
            <StatsConatiner
              key={companyStatsAndLatest?.counts?.investor_people}
              count={formatNumber(
                companyStatsAndLatest?.counts?.investor_people
              )}
              category="People"
            />
            <StatsConatiner
              key={companyStatsAndLatest?.counts?.investor_companies}
              count={formatNumber(
                companyStatsAndLatest?.counts?.investor_companies
              )}
              category="Companies"
            />
            <StatsConatiner
              key={companyStatsAndLatest?.counts?.added_last_month}
              count={formatNumber(
                companyStatsAndLatest?.counts?.added_last_month
              )}
              category="Added Last Month"
            />
            <StatsConatiner
              key={companyStatsAndLatest?.counts?.updated_last_month}
              count={formatNumber(
                companyStatsAndLatest?.counts?.updated_last_month
              )}
              category="Updated Last Month"
            />
            <StatsConatiner
              key={companyStatsAndLatest?.counts?.transition_last_three_months}
              count={formatNumber(
                companyStatsAndLatest?.counts?.transition_last_three_months
              )}
              category="Transition Last Three Months"
            />
          </ComapanyStats>

          <LatestAdded
            Heading="Latest Investors Added"
            dataLength={
              companyStatsAndLatest?.company
                ? companyStatsAndLatest?.company.length
                : 0
            }
          >
            {companyStatsAndLatest?.company &&
              companyStatsAndLatest?.company?.map((item) => (
                <AddLatest
                  ImageSrc={item?.logo}
                  alt="cananon-logo"
                  Heading={item?.name}
                  Content={item?.company_type}
                  link={item?.link}
                  userInfo={userInfo}
                  type="investor"
                />
              ))}
          </LatestAdded>

          <QuickSearch
            quickSearchData={investorTypeData}
            type="investor"
            handleQuickSearch={(data) => {
              handleQuickSearch(data);
            }}
          />

          <ImageContent>
            <ImageContentHeading
              Heading="Marketing Innovation"
              Information="Changing at a lighting pace. Get up to speed, stay competitive, and embrace the future of marketing."
              Content="Marketing Best Practices"
              onClick1={
                userInfo?.has_master_access || userInfo?.resource
                  ? handleResouces
                  : handleButtonClick
              }
            ></ImageContentHeading>

            <div>
              <ImageContentContainer
                ImagePath={Diversification}
                Heading="Content Marketing"
                Content1="Content marketing is a strategic approach that involves creating and distributing valuable, relevant, and consistent content to attract and engage a target audience. The primary goal is to build brand awareness, establish authority, and ultimately drive profitable customer actions, such as conversions or sales."
                Content2="This content can take various forms, including blog posts, videos, social media updates, infographics, and more, and it is designed to inform, educate, or entertain the audience rather than directly promote a product or service."
              ></ImageContentContainer>
            </div>

            <div className="mt-xxl">
              <ImageContentContainer
                // style={{ flexDirection: "row-reverse" }}
                ImagePath={InvestmentHub}
                Heading=" Lead Generation"
                Content1='Lead generation is the process of identifying and attracting potential customers or "leads" for a businesss products or services. This is typically achieved through various marketing strategies and tactics, such as online advertising, content marketing, social media, and email marketing. The goal is to capture the interest of individuals or companies who may have a genuine need or interest in what the business offers.'
                Content2="Lead management, on the other hand, is the systematic process of tracking, nurturing, and converting these leads into customers. It involves organizing and categorizing leads based on their level of interest and readiness to make a purchase."
              ></ImageContentContainer>
            </div>
          </ImageContent>

          <div className="mt-xl">
            <PortfolioCard
              Heading="Recent Resources"
              HeadingContent="Insights that matter most in capital raising."
              subscribed={userInfo?.has_master_access || userInfo.resource}
              onClick={
                !userInfo?.has_master_access && !userInfo.resource && handleLock
              }
            >
              {companyStatsAndLatest?.resources?.data &&
                companyStatsAndLatest?.resources?.data
                  ?.slice(0, 4)
                  .map((item) => (
                    <PortCards
                      CardPic={item?.cover_photo}
                      CardHeading={item?.header}
                      CardDate={moment.utc(item?.date).format("MMM D, YYYY")}
                      PicContent={item.category}
                      subscribed={subscribed}
                      id={item.id}
                      onCardClick={() => {
                        (userInfo?.has_master_access || userInfo?.resource) &&
                          navigate(
                            `/dashboard/resources/view/${item.id}/${btoa(
                              item.category
                            )}`
                          );
                      }}
                    ></PortCards>
                  ))}
            </PortfolioCard>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvestorHome;
