import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { areObjectsEqual } from "../../../../../../../helpers/areObjectsEqual";
import useScreenWidth from "../../../../../../../hooks/useScreenwidth";
import { getData } from "../../../../../../../services";
import { getInvestorData } from "../../../../../../../services/investorService";
import { setLoading } from "../../../../../../../store/slices/loaderSlice";
import ArrowDown from "../../../../../../Icons/ArrowDown";
import ImageViewer from "../../../../../../atoms/ImageViewer";
import ResetBtn from "../../../../../../atoms/ResetBtn/ResetBtn";
import Search from "../../../../../../atoms/Search";
import TypeDiv from "../../../../../../atoms/TypeDiv";
import NoContentCard from "../../../../../../molecules/NoContentCard";
import Pagination from "../../../../../../molecules/Pagination";
import SaveComponent from "../../../../../../molecules/SaveComponent";
import SaveAll from "../../../../../../molecules/SaveComponent/SaveAll";
import MultiselectFilter from "../../../../../../organisms/MultiselectFilter";
import SortFunctionality from "../../../../../../organisms/SortFunctionality";
import ContentSharingPhoneShimmer from "../../../../ContentSharing/Shimmer/ContentSharingPhones";
import ShimmerDatabase from "../../../../Products/Shimmer/shimmerDatabase";
import CreatedInvestorFilter from "../../../Filter/CreatedInvestorFilter";
import UpdateInvestorFilter from "../../../Filter/UpdatedInvestorFilter";
import AumFilter from "../../Filters/AumFilter";
import Location from "../../NewFilter/Location";
import Preferances from "../../NewFilter/Preferances";
import style from "./styles.module.scss";

const Company = () => {
  const [cookies] = useCookies(["t"]);
  const dispatch = useDispatch();
  const screenwidth = useScreenWidth();
  const location = useLocation();
  const hasMounted = useRef(false);

  const [quickSearchData, setQuickSearchData] = useState(location.state);

  const [showLoader, setShowLoader] = useState(false);
  const [searchvalue, setSearchvalue] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [InvestorList, setInvestoreList] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const [sort_by, setSort_By] = useState(null);

  const [investorType, setInvestorType] = useState();
  const [filterOpenInvestorType, setFilterOpenInvestorType] = useState(false);
  const [investorTypeFilter, setInvestorTypeFilter] = useState([]);
  const [tagData, setTagData] = useState([]);

  const [locationFilter, setLocationFilter] = useState({});
  const [moreFilterOpen, setMoreFilterOpen] = useState(false);
  const [updatedTag, setUpdatedTag] = useState({});
  const [filterOpenCreated, setFilterOpenCreated] = useState(false);
  const [filterOpenUpdated, setFilterOpenUpdated] = useState(false);

  const [createdFilter, setCreatedFilter] = useState();
  const [updatedFilter, setUpdatedFilter] = useState();
  const [filterOpenTags, setFilterOpenTags] = useState(false);
  const [tagFilter, setTagFilter] = useState();

  const [resetAll, setResetAll] = useState(false);

  const [filters, setFilters] = useState({});

  const [aum, setAum] = useState({ min: null, max: null });

  const [dropdownPositions, setDropdownPositions] = useState({
    aum: {},
    interest: {},
    location: {},
    target: {},
    tags: {},
    created: {},
    more: {},
  });
  // -----------------------------------------------------------------------------

  const handleView = (e, item) => {
    e.preventDefault();
    window.open(`/dashboard/company/${item}`, "_blank");
  };

  const fetchTags = async () => {
    const res = await getData({
      endpoint: "Search/getTags",
      params: {
        type: "Investor",
      },
      token: cookies.t,
    });
    let result = [];
    res.forEach((item) => {
      if (item !== null) {
        result.push({ value: item, label: item });
      }
    });
    setTagData(result);
  };

  const fetchInvestorData = async () => {
    dispatch(setLoading(true));
    const res = await getData({
      endpoint: "Taxonomy/getInvestorTypeList",
      params: {
        status: true,
      },
      token: cookies.t,
    });
    dispatch(setLoading(false));
    setInvestorType(res);
  };

  const getInvestorList = async () => {
    dispatch(setLoading(true));
    try {
      const res = await getInvestorData({
        token: cookies.t,
        search: searchvalue,
        currentPage,
        itemsPerPage,
        investorType: quickSearchData
          ? quickSearchData?.value
            ? JSON.stringify([quickSearchData?.value])
            : null
          : investorTypeFilter && investorTypeFilter.length > 0
          ? JSON.stringify(investorTypeFilter)
          : null,
        // investorType: JSON.stringify(investorTypeFilter),
        aum: aum,
        // locationData: locationData,
        country:
          locationFilter?.Country &&
          locationFilter?.["Country"]?.map((country) => country.label),
        state:
          locationFilter?.State &&
          locationFilter?.["State"]?.map((state) => state.label),
        city:
          locationFilter?.City &&
          locationFilter?.["City"]?.map((city) => city.label),
        sector:
          filters?.Sector && filters?.["Sector"]?.map((sector) => sector.value),
        geography:
          filters?.Geography &&
          filters?.["Geography"]?.map((geography) => geography.value),
        product_type:
          filters?.["Product Type"] &&
          filters?.["Product Type"]?.map((type) => type.value),
        // interestData: interestData,
        tags: tagFilter,
        // updatedTag?.Tags && updatedTag?.["Tags"]?.map((tag) => tag.label),
        created_start_date: createdFilter?.created_start_date,
        created_end_date: createdFilter?.created_end_date,
        updated_start_date: updatedFilter?.updated_start_date,
        updated_end_date: updatedFilter?.updated_end_date,
        sort_by: sort_by,
      });
      if (res) {
        setShowLoader(true);
      }
      setInvestoreList(res);
    } catch (exp) {
      console.log("InvestorList", exp);
    }
  };

  useEffect(() => {
    if (location.state && !hasMounted.current) {
      hasMounted.current = true; // Mark as mounted
      return;
    }

    getInvestorList();
    // setResetAll(false);
  }, [
    searchvalue,
    currentPage,
    itemsPerPage,
    investorTypeFilter,
    aum,
    filters,
    locationFilter,
    updatedTag,
    tagFilter,
    updatedFilter,
    createdFilter,
    sort_by,
    refetch,
  ]);

  useEffect(() => {
    fetchInvestorData();
    fetchTags();
    // updateDropdownPositions();
  }, []);

  // Pagination handle -----------------------------

  const totalItems = InvestorList?.total_record;

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  //  ----------------------------------------------

  // // updating locations ----------------------------
  const updateDropdownPositions = () => {
    const investorType = document.getElementById("InvestorTypeId");
    const investorTypeRect = investorType?.getBoundingClientRect();

    const aumDropdown = document.getElementById("aumDropdown");
    const aumDropdownRect = aumDropdown?.getBoundingClientRect();

    const interestDropdown = document.getElementById("interestDropdown");
    const interestDropdownRect = interestDropdown?.getBoundingClientRect();

    const locationDropdown = document.getElementById("locationDropdown");
    const locationDropdownRect = locationDropdown?.getBoundingClientRect();

    const targetDropdown = document.getElementById("targetDropdown");
    const targetDropdownRect = targetDropdown?.getBoundingClientRect();

    const tagsDropdown = document.getElementById("tagsDropdown");
    const tagsDropdownRect = tagsDropdown?.getBoundingClientRect();

    const createdDropdown = document.getElementById("createdDropdown");
    const createdDropdownRect = createdDropdown?.getBoundingClientRect();

    const updatedDropdown = document.getElementById("updatedDropdown");
    const updatedDropdownnRect = updatedDropdown?.getBoundingClientRect();

    const moreFilters = document.getElementById("more_Filters");
    const moreFiltersRect = moreFilters?.getBoundingClientRect();

    setDropdownPositions({
      investor: {
        left: investorTypeRect?.left,
        right: investorTypeRect?.right,
      },
      aum: {
        left: aumDropdownRect?.left,
      },
      interest: {
        left: interestDropdownRect?.left,
      },
      location: {
        left: locationDropdownRect?.left,
      },
      target: {
        left: targetDropdownRect?.left,
      },
      tags: {
        left: tagsDropdownRect?.left,
      },
      created: {
        left: createdDropdownRect?.left,
      },
      updated: {
        left: updatedDropdownnRect?.left,
      },
      more: {
        right: moreFiltersRect?.right,
      },
    });
  };

  useEffect(() => {
    updateDropdownPositions();
  }, [screenwidth]);
  // --------------------------------------------------------------------------------------

  const handleFilterChange = (values) => {
    if (Object.keys(values)?.length > 0) {
      const modifiedData = Object.keys(values).reduce((acc, key) => {
        const newKey = key;
        acc[newKey] = values[key];
        return acc;
      }, {});

      // Check if the new modifiedData is different from the existing filters
      if (!areObjectsEqual(modifiedData, filters)) {
        if (currentPage !== 1) setCurrentPage(1);
        setFilters(modifiedData);
      }
    } else {
      setFilters({});
    }
  };

  const handleSearch = (e) => {
    setSearchvalue(e);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  const handleResetAll = () => {
    setResetAll(true);
  };

  const shouldShowSaveAll = () => {
    const hasFilters = filters && Object.keys(filters).length > 0;

    const hasLocationFilter =
      locationFilter && Object.keys(locationFilter).length > 0;

    const hasTagFilter = tagFilter && tagFilter.length > 0;

    const hasCreatedFilter =
      createdFilter &&
      typeof createdFilter === "object" &&
      !Array.isArray(createdFilter) &&
      Object.keys(createdFilter).length > 0;

    const hasUpdatedFilter =
      updatedFilter &&
      typeof updatedFilter === "object" &&
      !Array.isArray(updatedFilter) &&
      Object.keys(updatedFilter).length > 0;

    const hasAum = aum && (aum.min || aum.max);

    const hasInvestorType = investorTypeFilter?.length > 0;

    const hasQuickSearchData = quickSearchData?.value;

    const result =
      hasFilters ||
      searchvalue ||
      hasLocationFilter ||
      hasTagFilter ||
      hasCreatedFilter ||
      hasUpdatedFilter ||
      hasAum ||
      hasInvestorType ||
      hasQuickSearchData;

    return result;
  };

  return (
    <div className={style.mainCompanyDiv}>
      <div className="d-flex justify-content-between">
        <div className="h1-i">Companies</div>
        <Search
          variant="search_md"
          onSearchEmit={handleSearch}
          className={style.search_responsive_btn}
        />
      </div>

      <div className={style.companiesHeader}>
        {/* <div className={style.search_header}>
          <Search
            variant="search_sm"
            bgStyle="white"
            onSearchEmit={handleSearch}
            className={style.search_responsive_btn}
          />
          <FilterComponent
            onFilterChange={handleFilterChange}
            type="investor"
            headingMobile={false} // Set to true if you want to hide the "Filters" button text on mobile
            disableKeysUntilCondition={true} // Pass the disableKeysUntilCondition object
            reset={resetAll}
            id="more_Filters"
            className={style.reset_responsive_Mobile_btn}
          />
        </div> */}

        <div className={`d-flex gap-2 mt-3 flex-wrap`}>
          <SortFunctionality
            reset={resetAll}
            handleSort={(sort) => {
              setSort_By(sort);
            }}
          />
          <MultiselectFilter
            title="Investor Type"
            icon={<ArrowDown />}
            data={investorType}
            handleFilter={(filter) => {
              setInvestorTypeFilter(filter);
              setResetAll(false);
            }}
            open={filterOpenInvestorType}
            setOpen={setFilterOpenInvestorType}
            id="InvestorTypeId"
            dropdownPosition={dropdownPositions.investor}
            sizeType="small"
            reset={resetAll}
            quickSearchData={quickSearchData}
            setQuickSearchData={setQuickSearchData}
          />

          {/* <MultiselectFilter
            title="Tags"
            icon={<ArrowDown />}
            data={tagData}
            handleFilter={(filter) => {
              setTagFilter(filter);
            }}
            open={filterOpenTags}
            setOpen={setFilterOpenTags}
            setQuickSearchData={setQuickSearchData}
            id="tagsDropdown"
            // reset={resetAll}
          /> */}

          <AumFilter
            handleAum={(data) => {
              setAum(data);
            }}
            reset={resetAll}
            id="aumDropdown"
            dropdownPosition={dropdownPositions.aum}
          />
          <Location
            onFilterChange={(values) => {
              setLocationFilter(values);
            }}
            type="product"
            headingtext="Location"
            headingMobile={false}
            disableKeysUntilCondition={true}
            reset={resetAll}
            id={"location_filter"}
            tabresponsive
          />
          {/* 
          <CreatedInvestorFilter
            handleFilter={(data) => setCreatedFilter(data)}
            filterOpenCreated={filterOpenCreated}
            setFilterOpenCreated={setFilterOpenCreated}
            id="createdDropdown"
            dropdownPosition={dropdownPositions.created}
            type="small"
            reset={resetAll}
          />
          <UpdateInvestorFilter
            handleFilter={(data) => setUpdatedFilter(data)}
            filterOpenUpdated={filterOpenUpdated}
            setFilterOpenUpdated={setFilterOpenUpdated}
            id="updatedDropdown"
            dropdownPosition={dropdownPositions.updated}
            type="small"
            reset={resetAll}
          /> */}
          <Preferances
            onFilterChange={(values) => {
              setFilters(values);
            }}
            type="product"
            headingtext="Preference"
            headingMobile={false} // Set to true if you want to hide the "Filters" button text on mobile
            disableKeysUntilCondition={true} // Pass the disableKeysUntilCondition object
            reset={resetAll}
            id="more_Filters"
            handleAum={(data) => {
              setAum(data);
            }}
            tabresponsive
            // className={style.reset_responsive_Mobile_btn}
          />

          {/* <FilterComponent
            onFilterChange={handleFilterChange}
            type="investor"
            headingMobile={false} // Set to true if you want to hide the "Filters" button text on mobile
            disableKeysUntilCondition={true} // Pass the disableKeysUntilCondition object
            reset={resetAll}
            id="more_Filters"
            className={style.reset_responsive_btn}
            isDateRange
            dateRangeCount={2}
            dateRangeNames={["Created Date", "Updated Date"]}
          /> */}
          {/* <UpdatedTag
            isDateRange
            dateRangeCount={2}
            dateRangeNames={["Created Date", "Updated Date"]}
            filterOpen={moreFilterOpen}
            headingtext="Updated On & Tags"
            setFilterOpen={setMoreFilterOpen}
            reset={resetAll}
            type="product"
            onFilterChange={(filters) => {
              setUpdatedTag(filters);
            }}
          /> */}
          <CreatedInvestorFilter
            handleFilter={(data) => setCreatedFilter(data)}
            filterOpenCreated={filterOpenCreated}
            setFilterOpenCreated={setFilterOpenCreated}
            id="createdDropdown"
            dropdownPosition={dropdownPositions.created}
            type="small"
            reset={resetAll}
          />
          <UpdateInvestorFilter
            handleFilter={(data) => setUpdatedFilter(data)}
            filterOpenUpdated={filterOpenUpdated}
            setFilterOpenUpdated={setFilterOpenUpdated}
            id="updatedDropdown"
            dropdownPosition={dropdownPositions.updated}
            type="small"
            reset={resetAll}
          />
          <MultiselectFilter
            title="Tags"
            icon={<ArrowDown />}
            data={tagData}
            handleFilter={(filter) => {
              setTagFilter(filter);
              setResetAll(false);
            }}
            open={filterOpenTags}
            setOpen={setFilterOpenTags}
            setQuickSearchData={setQuickSearchData}
            id="tagsDropdown"
            dropdownPosition={dropdownPositions.tags}
            sizeType="small"
            reset={resetAll}
            info={true}
            information={"Based on added Tags"}
          />

          <ResetBtn onReset={() => handleResetAll()} type="small" />

          {shouldShowSaveAll() && InvestorList?.data?.length > 0 && (
            <SaveAll
              type={"Company"}
              saveType={"InvestorCompany"}
              filters={filters}
              search={searchvalue}
              setRefetch={setRefetch}
              locationFilter={locationFilter}
              tagFilter={tagFilter}
              createdFilter={createdFilter}
              updatedFilter={updatedFilter}
              aum={aum}
              investor_types={
                quickSearchData ? [quickSearchData?.value] : investorTypeFilter
              }
            />
          )}
        </div>
      </div>
      {!showLoader ? (
        <>
          {screenwidth > 900 ? (
            <ShimmerDatabase />
          ) : (
            <ContentSharingPhoneShimmer type="Database" />
          )}
        </>
      ) : (
        <>
          <div className={style.investorMapWrap}>
            {InvestorList?.data?.length > 0 ? (
              <>
                {InvestorList?.data?.map((item) => {
                  return (
                    <div className={style.investorDataWrap}>
                      <div className={style.investor_inner_datawrap}>
                        <div className={style.investorInfoWrap}>
                          <ImageViewer
                            type="Company"
                            imageUrl={item.profile_photo}
                          />
                          <div>
                            <div
                              className={`d-flex align-items-center gap-3 mb-1 ${style.Product_database_heading_container}`}
                            >
                              <h6
                                className={style.companyName}
                                onClick={(e) => {
                                  handleView(e, item?.company_id);
                                }}
                              >
                                {item?.name}
                              </h6>
                              <TypeDiv
                                classes={style.mbres_none}
                                data={item?.type}
                                color={
                                  item?.type === "Investor/Manager/Sponsor"
                                    ? "#B5E4CA"
                                    : item?.type === "Investor"
                                    ? "#FFBC99"
                                    : "#CABDFF"
                                }
                              />
                            </div>
                            {/* <div className={style.companyType}>{item?.type}</div>
                  <div className={style.companyType}>{item?.email}</div> */}
                            {item?.address?.length > 0 && (
                              <div
                                className={`${style.companyType} ${style.mbres_none}`}
                              >
                                {item?.address?.[0]?.city +
                                  ", " +
                                  item?.address?.[0]?.state_region +
                                  ", " +
                                  item?.address?.[0]?.country}
                              </div>
                            )}
                            <div
                              className={`${style.companyType} ${style.mbres_none}`}
                            >
                              <div className="d-flex align-items-center">
                                Added On :{" "}
                                {moment
                                  .utc(item?.created_date)
                                  .format("MMM D, YYYY")}
                                <div className="dot"></div>
                                Last Update:{" "}
                                {moment
                                  .utc(item?.updated_date)
                                  .format("MMM D, YYYY")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={style.database_none_wrapper}>
                          <>
                            <TypeDiv
                              data={item?.type}
                              classes={style.typediv_productDatabse}
                              color={
                                item?.type === "Investor/Manager/Sponsor"
                                  ? "#B5E4CA"
                                  : item?.type === "Investor"
                                  ? "#FFBC99"
                                  : "#CABDFF"
                              }
                            />
                            {item?.address?.length > 0 && (
                              <div className={`${style.companyType}`}>
                                {item?.address?.[0]?.city +
                                  ", " +
                                  item?.address?.[0]?.state_region +
                                  ", " +
                                  item?.address?.[0]?.country}
                              </div>
                            )}
                            <div className={`${style.companyType}`}>
                              <div className="">
                                Added On :{" "}
                                {moment
                                  .utc(item?.created_date)
                                  .format("MMM D, YYYY")}
                              </div>
                            </div>
                            <div className={`${style.companyType}`}>
                              Last Update:{" "}
                              {moment
                                .utc(item?.updated_date)
                                .format("MMM D, YYYY")}
                            </div>
                          </>
                        </div>
                      </div>
                      <div className={style.investorButtons}>
                        <SaveComponent
                          type="Company"
                          key={item?.company_id}
                          id={item.company_id}
                          saved_id={item.saved_list}
                          btnType="btn-outline-dark"
                        />

                        <div
                          className="btn btn-outline-dark"
                          onClick={(e) => {
                            handleView(e, item?.company_id);
                          }}
                        >
                          View
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <NoContentCard title="No Data Found" />
              </>
            )}
          </div>

          {/* Pagination  */}
          {InvestorList?.total_record >= 10 && (
            <div className="mt-2 ms-sm-4">
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={totalItems}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Company;
