import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { heightConfimationStyles } from "../../../assets/styles/toast";
import { deleteData, postData } from "../../../services";
import { fetchGroupData } from "../../../store/slices/savedSlice";
import { useSubscription } from "../../../SubscriptionContext";
import SelectDropdown from "../../atoms/Dropdown";
import SavedBlueIcon from "../../Icons/SavedBlueIcon";
import SavedIcon from "../../Icons/SavedIcon";
import { convertToValueLabel } from "../../pages/dashboard/SavedList";
import Modal from "../Modal/Normal";
import style from "./save_component.module.scss";

const capitalizeType = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const SaveComponent = ({ type, id, saved_id, btnType = "" }) => {
  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  const normalizedType = capitalizeType(type); // Normalize the type here
  const [cookies] = useCookies(["t", "cuid"]);
  const dispatch = useDispatch();
  const [isSaved, setIsSaved] = useState(false);
  const [savedId, setSavedId] = useState(saved_id);
  const { handleUserClick, isSubscriptionExpired } = useSubscription();
  const [showModal, setShowModal] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groups, setGroups] = useState([]); // Holds existing groups

  const { company, people, product } = useSelector(
    (state) => state.savedGroups
  );

  useEffect(() => {
    const userId = cookies.cuid;
    if (normalizedType === "Company" && !company) {
      dispatch(fetchGroupData({ type: "company", userId }));
    } else if (normalizedType === "Product" && !product) {
      dispatch(fetchGroupData({ type: "product", userId }));
    } else if (normalizedType === "People" && !people) {
      dispatch(fetchGroupData({ type: "people", userId }));
    }
  }, [normalizedType, company, product, people, dispatch, cookies.cuid]);

  useEffect(() => {
    setSavedId(saved_id);
    setIsSaved(saved_id && saved_id !== "00000000-0000-0000-0000-000000000000");
  }, [saved_id]);

  const handleSave = async () => {
    setIsSaved(true);
    setShowModal(false);

    toast.success(
      `${normalizedType === "People" ? "Profile" : normalizedType} saved to ${
        selectedGroup || newGroupName
      } group.`,
      { style: heightConfimationStyles, duration: 1000 }
    );

    const res = await postData({
      endpoint: "SavedList/addTOSavedList",
      data: {
        map_id: id,
        map_type: normalizedType,
        group: selectedGroup || newGroupName,
      },
      token: cookies.t,
    });

    if (newGroupName) {
      const groupType = normalizedType.toLowerCase(); // Convert to lowercase for API call consistency
      dispatch(fetchGroupData({ type: groupType, userId: cookies.cuid }));
    }

    if (res) {
      setSavedId(res?.data?.data?.id);
      setNewGroupName(null);
      setSelectedGroup(null);
    }
  };

  const handleRemove = async () => {
    setIsSaved(false);
    const res = await deleteData({
      endpoint: "SavedList/removeFromList",
      params: { id: savedId },
      token: cookies.t,
    });
    if (res) {
      setSavedId("");
      setIsSaved(false);
    }
  };

  const handleClick = (e) => {
    if (isSubscriptionExpired) {
      // && userInfo?.role === "Manager/Sponsor") {
      handleUserClick(e);
      return;
    }
    if (isSaved) {
      handleRemove();
    } else {
      setShowModal(true);
    }
  };

  const handleCreateNewGroup = async () => {
    const res = await postData({
      endpoint: "Groups/create",
      data: { name: newGroupName },
      token: cookies.t,
    });
    if (res?.data) {
      const newGroup = { label: newGroupName, value: res.data.group.id };
      setGroups([...groups, newGroup]);
      setSelectedGroup(newGroup.value);
      setNewGroupName(""); // Clear newGroupName when a new group is created
    }
  };

  const data =
    normalizedType === "Company"
      ? convertToValueLabel(company)
      : normalizedType === "Product"
      ? convertToValueLabel(product)
      : convertToValueLabel(people);

  const handleCancel = () => {
    setShowModal(false);
    setSelectedGroup(null);
    setNewGroupName(null);
  };

  return (
    <>
      <button
        key={id}
        onClick={handleClick}
        style={{
          cursor: "pointer",
          color: btnType === "btn-outline-dark" ? "black" : "",
        }}
        className={`${style.save} ${
          isSaved ? style.clicked : style.not_clicked
        } ${btnType === "btn-outline-dark" && "btn-outline-dark"}`}
      >
        {isSaved ? (
          <i className="d-flex" key={id}>
            <SavedBlueIcon />
          </i>
        ) : (
          <i className="d-flex" key={id}>
            <SavedIcon
              className={
                btnType !== "btn-outline-dark"
                  ? style.saved_company_icon_container
                  : ""
              }
            />
          </i>
        )}
        {isSaved ? " Saved" : " Save"}
      </button>

      {showModal && (
        <Modal
          title="Select or create group"
          isActive={showModal}
          id={1}
          onClose={() => {
            setShowModal(false);
            setNewGroupName("");
            setSelectedGroup(null);
          }}
          width="500px"
          overflowStyle={{ overflow: "visible" }}
        >
          <label>Select an existing group</label>
          <SelectDropdown
            placeholder="Select Group"
            onChange={(option) => {
              setSelectedGroup(option);
              setNewGroupName(""); // Clear newGroupName when an existing group is selected
            }}
            selectedValue={selectedGroup}
            data={data}
          />
          <div className="d-flex align-items-center justify-content-center my-2">
            <span className="fw-bold">OR</span>
          </div>
          <div className="d-flex flex-column">
            <label>Create a new group</label>
            <input
              placeholder="Enter group name"
              value={newGroupName}
              onChange={(e) => {
                setNewGroupName(e.target.value);
                setSelectedGroup(null); // Clear selectedGroup when entering a new group name
              }}
            />
          </div>
          <div className="d-flex gap-3 mb-3 w-100 mt-4">
            <button
              className="btn btn-primary"
              style={{ flex: 1 }}
              onClick={handleSave}
              disabled={!selectedGroup && !newGroupName}
            >
              Save to Group
            </button>
            <button
              className="btn btn-outline-dark"
              style={{ flex: 1 }}
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SaveComponent;
