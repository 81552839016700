import { getData } from "./index";

export const getInvestorData = async ({
  token,
  search,
  people_search,
  currentPage,
  itemsPerPage,
  investorType,
  locationData,
  interestData,
  tags,
  aum,
  updatedFilter,
  createdFilter,
  type,
  is_people,
  country,
  state,
  city,
  sector,
  geography,
  product_type,
  created_end_date,
  created_start_date,
  updated_end_date,
  updated_start_date,
  sort_by,
}) => {
  if (type === "people") {
    locationData = locationData && JSON.parse(locationData);
    interestData = interestData && JSON.parse(interestData);
    createdFilter = createdFilter && JSON.parse(createdFilter);
    updatedFilter = updatedFilter && JSON.parse(updatedFilter);
  }

  const pagination = {
    page: currentPage,
    page_limit: itemsPerPage,
    search_query: search,
    people_search: people_search,
    investor_types:
      investorType && investorType.length > 0
        ? type === "roadshow"
          ? JSON.stringify(investorType)
          : investorType
        : null,
    min_aum: aum?.min,
    max_aum: aum?.max,
    country:
      JSON.stringify(country) ||
      (locationData?.country && [locationData?.country]),
    state: JSON.stringify(state) || JSON.stringify(locationData?.state),
    city: JSON.stringify(city) || JSON.stringify(locationData?.city),
    product_type:
      JSON.stringify(product_type) ||
      JSON.stringify(interestData?.product_type),
    sector: JSON.stringify(sector) || JSON.stringify(interestData?.sector),
    geography:
      JSON.stringify(geography) || JSON.stringify(interestData?.geography),
    tags:
      type === "people" ? tags : tags?.length > 0 ? JSON.stringify(tags) : null,
    created_start_date: created_start_date,
    created_end_date: created_end_date,
    updated_start_date: updated_start_date,
    updated_end_date: updated_end_date,
    is_people: is_people || false,
    sort_by: sort_by,
  };

  try {
    const response = await getData({
      endpoint: "Search/searchInvestor",
      params: pagination,
      token: token,
    });
    return response;
  } catch (exp) {
    console.log("Get Investor Error");
  }
};

export const getPeopleData = async ({
  type,
  token,
  page,
  page_limit,
  search_query,
  product_type,
  product_category,
  sector,
  geography,
  tags,
  updatedFilter,
  createdFilter,
  country,
  state,
  city,
  investorType,
  fundingFilter,
  product_strategy,
  product_structure,
  aum,
  teams,
  sort_by,
}) => {
  const params = {
    page,
    page_limit,
    search_query,
    product_type: JSON.stringify(product_type),
    created_start_date: createdFilter?.created_start_date,
    created_end_date: createdFilter?.created_end_date,
    updated_start_date: updatedFilter?.updated_start_date,
    updated_end_date: updatedFilter?.updated_end_date,
    product_strategy: JSON.stringify(product_strategy),
    product_category:
      product_category?.length > 0 ? JSON.stringify(product_category) : null,
    product_structure:
      product_structure?.length > 0 ? JSON.stringify(product_structure) : null,
    sector: JSON.stringify(sector),
    geography: JSON.stringify(geography),
    tags: tags?.length > 0 ? JSON.stringify(tags) : null,
    country: JSON.stringify(country),
    state: JSON.stringify(state),
    city: JSON.stringify(city),
    min_aum: aum?.min,
    max_aum: aum?.max,
    min_funding: fundingFilter?.min,
    max_funding: fundingFilter?.max,
    investor_types: investorType && JSON.stringify(investorType),
    teams: teams?.length > 0 ? JSON.stringify(teams) : null,
    sort_by: sort_by,
  };

  try {
    const response = await getData({
      endpoint:
        type === "investor"
          ? "Search/investorPeopleSearch"
          : "Search/productPeopleSearch",
      params: params,
      token: token,
    });
    return response;
  } catch (exp) {
    console.log("Get Investor Error");
  }
};
