import moment from "moment";
import { useNavigate } from "react-router-dom";
import Default from "../../../assets/images/default.png";
import { useSubscription } from "../../../SubscriptionContext";
import "./blog-card.scss";

const BlogCard = ({ data, type }: any) => {
  const { isSubscriptionExpired, handleUserClick } = useSubscription();
  const navigate = useNavigate();
  const handleReadMore = () => {
    if (type === "Library") {
      navigate(`/dashboard/library/view/${data?.id}/${btoa(data?.category)}`);
    } else {
      navigate(`/dashboard/resources/view/${data?.id}/${btoa(data?.category)}`);
    }
  };

  return (
    <div
      className="blog-card cursor-pointer"
      onClick={(e) =>
        isSubscriptionExpired ? handleUserClick(e) : handleReadMore()
      }
    >
      <div className="blog-image">
        <img
          src={data?.thumbnail || data?.cover_photo || Default}
          alt="blog"
          className="image"
        />
        <span className="catregory-name-pic-container">{data?.category}</span>
      </div>
      <div className="h4-i semi-light mb-2 font-20">{data.header}</div>
      <div className="p-grey mb-1">
        {moment.utc(data?.date || data?.modified_date).format("MMM D, YYYY")}
      </div>
      {/* <div className="p mb-1">{data?.text.slice(0, 120)}...</div> */}
      {/* <div className="quill quill-change">
        <div className="ql-container ql-snow ql-no-border">
          <div
            className="ql-editor blog-content extar-description-styles extra-card-blog-styles spacing-issue-rte"
            style={{
              padding: "0 !important",
              display: "inline",
            }}
            dangerouslySetInnerHTML={{
              __html: data?.text.substring(0, 300),
            }}
          ></div>
          <div style={{ display: "inline" }}>
            {data?.text?.length > 300 ? "..." : " "}
          </div>
        </div>
      </div> */}
      <div className="text-color">
        {data?.sub_header?.slice(0, 200)}
        {data?.sub_header?.length > 200 && <>...</>}
      </div>
      <div
        className="view-more cursor-pointer mt-2"
        style={{ fontSize: "16px" }}
        onClick={(e) =>
          isSubscriptionExpired ? handleUserClick(e) : handleReadMore()
        }
      >
        Read More
      </div>
    </div>
  );
};

export default BlogCard;
