import moment from "moment";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSubscription } from "../../../../../SubscriptionContext";
import InputLayout from "../../../../atoms/InputLayout";
import PageCard from "../../../../atoms/PageCard";

const PreferenceNotes = ({ data }) => {
  const location = useLocation();
  const { top } = useSubscription();

  const numericValue = parseInt(top, 10);

  useEffect(() => {
    const hash = location.hash?.substring(1); // Remove '#' from the hash
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        const offset = 72; // Height of the sticky navbar
        const elementPosition =
          element.getBoundingClientRect().top + window.scrollY; // Element's position relative to the page
        const offsetPosition = elementPosition - offset - numericValue;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [location]);

  return (
    <>
      <h3 className="h3-j mb-3">
        {data?.length === 1 ? "Preference Note" : "Preference Notes"}
      </h3>

      {data?.length > 0 &&
        data.map((item: any) => (
          <PageCard
            key={item?.id || Math.random()} // Add a unique key for each element
            customStyles={{ backgroundColor: "#F9F9F9" }}
            classes="p-4 mb-4"
            id={item?.id}
          >
            <div className="row g-3">
              <InputLayout classes="w-100">
                <div className="p-dark-product-company">Title</div>
                <div className="p-light-small-product-company">
                  {item?.title || "--"}
                </div>
              </InputLayout>

              {item?.date && (
                <InputLayout>
                  <div className="p-dark-product-company">Date</div>
                  <div className="p-light-small-product-company">
                    {moment(item?.date).format("MMMM YYYY") || "--"}
                  </div>
                </InputLayout>
              )}
            </div>

            <InputLayout classes="mt-4">
              <div className="p-dark-product-company">Note</div>
              <div className="p-light-small-product-company">
                {item?.notes || "--"}
              </div>
            </InputLayout>
          </PageCard>
        ))}
    </>
  );
};

export default PreferenceNotes;
