import { Field, Form, Formik } from "formik";
import { useState } from "react";
import SelectDropdown from "../../../../../atoms/Dropdown";
import InputLayout from "../../../../../atoms/InputLayout";
import CustomDropzone from "../../../../../molecules/Dropzone";
// import { TagInput } from "rsuite";
import { useContext } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  confimationStyles,
  warningStyles,
} from "../../../../../../assets/styles/toast";
import { LoadingContext } from "../../../../../../contexts/LoadingContext";
import { videoFileName } from "../../../../../../helpers/fileName";
import { subTypeData } from "../../../../../../helpers/staticData";
import { postData } from "../../../../../../services";
import {
  addProductDataRoom,
  editProductDataRoom,
} from "../../../../../../services/productHelper";
import { fetchThumbnail } from "../../../../../../services/productsService";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import Error from "../../../../../atoms/Error";
import TagInput from "../../../../../atoms/TagInput";
import ImageSelector from "../../../../../molecules/ImageSelector/ImageSelector";
import { DateSelector } from "../../../../../molecules/MonthYearSelector";
import QuillEditor from "../../../../../molecules/QuillEditor/QuillEditor";

const ImageSelectorStyle = {
  borderRadius: "10px",
  border: "1px solid #EFEFEF",
  // padding: "10px",
  width: "12rem",
  height: "9rem",
};

const AddDataForm = ({ onClose, id, initialData, onAddorUpdate }) => {
  const [cookies] = useCookies(["t"]);
  const { id: pid } = useParams();
  const dispatch = useDispatch();

  const { startLoading, stopLoading, setFileName, isLoading, setInProcess } =
    useContext(LoadingContext);

  const [isSaving, setIsSaving] = useState(false);

  const initialValues = {
    type: initialData?.type || "",
    tag: initialData?.tag || null,
    date: initialData?.upload_date || null,
    document: initialData?.document || "",
    name: initialData?.name || "",
    description: initialData?.description || "",
    link: initialData?.link || null,
    sub_type: initialData?.sub_type || null,
    thumbnail: initialData?.thumbnail || null,
    is_featured: initialData?.is_featured || false,
    podcast_thumbnail: initialData?.podcast_thumbnail || "",
    user_role: "Customer",
  };

  const validationSchema = Yup.object()
    .shape({
      name: Yup.string().required("Video Name is required"),
      type: Yup.string().required("Type is required"),
      document: Yup.string().when("type", {
        is: (type) => type === "Document",
        then: () =>
          Yup.string().required("Document URL is required for other types"),
        otherwise: () => Yup.string().notRequired(),
      }),
      podcast_thumbnail: Yup.string().test(
        "podcast_thumbnail",
        "Thumbnail is required",
        function (value) {
          const { type, document, podcast_thumbnail } = this.parent;
          if (type === "Podcast" && document && !podcast_thumbnail) {
            return false;
          }
          return true;
        }
      ),
      // date: Yup.date().required("Date is required"),
      description: Yup.string(),
      sub_type: Yup.string().when("type", {
        is: (type) => type === "Document", // Change here to use a string value
        then: () => Yup.string().required(),
        otherwise: () => Yup.string().notRequired(), // Change here to use notRequired()
      }),
    })
    .test(
      "either-link-or-document",
      "Either link or document is required for Podcast",
      function (value) {
        const { type, link, document } = value;
        if (type === "Podcast" && !link && !document) {
          return (
            this.createError({
              path: "link",
              message:
                "Either Podcast link or Audio File is required for Podcast",
            }) ||
            this.createError({
              path: "document",
              message:
                "Either Podcast link or Audio File is required for Podcast",
            })
          );
        } else if (type === "Podcast" && link && document) {
          return (
            this.createError({
              path: "link",
              message:
                "Please provide either a podcast link or upload a file, not both.",
            }) ||
            this.createError({
              path: "document",
              message:
                "Please provide either a podcast link or upload a file, not both.",
            })
          );
        } else if (type === "Video" && !link && !document) {
          return (
            this.createError({
              path: "link",
              message: "Either Vimeo link or Video File is required for Video",
            }) ||
            this.createError({
              path: "document",
              message: "Either Vimeo link or Video File is required for Video",
            })
          );
        } else if (type === "Video" && link && document) {
          return (
            this.createError({
              path: "link",
              message:
                "Please provide either a Vimeo link or upload a file, not both.",
            }) ||
            this.createError({
              path: "document",
              message:
                "Please provide either a Vimeo link or upload a file, not both.",
            })
          );
        }
        return true;
      }
    );

  const handleWorker = ({
    id,
    upload_link,
    videoFile,
    videoUri,
    filename,
    videoId,
  }) => {
    // console.log({ upload_link, videoFile });
    startLoading(); // Start showing the loader

    const worker = new Worker(
      new URL(
        "../../../../../../contexts/LoadingContext/worker",
        import.meta.url
      )
    ); // Adjust the path as necessary

    const cookies = document.cookie;

    worker.onmessage = (e) => {
      const { progress, completed, error, inProcess, close } = e.data;

      // console.log("e,", e.data);

      if (inProcess) {
        setInProcess(true);
      }

      if (completed) {
        setInProcess(false);
        stopLoading();
        setFileName(null);
        localStorage.removeItem("in-process");
      }

      if (close) {
        localStorage.removeItem("in-process");
        worker.terminate();
      }

      if (error) {
        worker.terminate(); // Terminate the worker on error
      }
    };

    worker.postMessage({
      id,
      cookies,
      uploadLink: upload_link,
      videoFile,
      chunkSize: 150 * 1024 * 1024,
      videoUri,
      filename,
      videoId,
    });
  };

  const getVideoId = (url) => {
    if (url) {
      const segments = url.split("/"); // Split by "/"
      return segments.pop(); // Get the last part, which is the video ID
    } else {
      return "";
    }
  };

  const handleVideoUpload = async (values) => {
    let link = null;
    let updatedValues = values;
    let vimeoRes = null;
    let videoFile = null;
    let filename = null;
    let videoId = null;

    if (values.type === "Video" && values.document && !values.link) {
      // console.log("size", values.document.size);

      if (isLoading) {
        toast(
          "A video upload is currently in progress. Please try again later.",
          { duration: 1800, style: warningStyles }
        );
        return { success: false };
      }

      try {
        const res = await postData({
          endpoint: "Wrapper/initiate-upload",
          params: { fileSize: values.document.size },
        });

        // Update link and file details
        link = `https://vimeo.com/${getVideoId(res?.data?.videoUri)}`;
        videoFile = values.document;
        filename = videoFileName(values.document);
        setFileName(filename);

        updatedValues = {
          ...values,
          document: null, // Set document to null as it's already uploaded
          link, // Use the generated Vimeo link
        };

        vimeoRes = res;
        videoId = getVideoId(res?.data?.videoUri);
      } catch (uploadError) {
        console.error("Error during Vimeo upload:", uploadError);
        toast.error("Failed to initiate video upload", { duration: 1500 });
        return { success: false };
      }
    }

    return {
      success: true,
      updatedValues,
      link,
      vimeoRes,
      videoFile,
      filename,
      videoId,
    };
  };

  const handleSubmit = async (values) => {
    setIsSaving(true);
    dispatch(setLoading(true));

    let videoUploadResult = { success: true, updatedValues: values };

    if (values.type === "Video" && values.document && !values.link) {
      videoUploadResult = await handleVideoUpload(values);
      if (!videoUploadResult.success) {
        setIsSaving(false);
        dispatch(setLoading(false));
        return; // Early exit in case of video upload error
      }
    }

    let { updatedValues, link, vimeoRes, videoFile, filename, videoId } =
      videoUploadResult;

    if (
      (values.type === "Video" || values.type === "Podcast") &&
      values.link &&
      !values.thumbnail
    ) {
      const thumbnail = await handleLinkBlur(null, values.link);

      updatedValues = { ...values, thumbnail };
    }

    try {
      // Make the API call with updated values
      const res = await addProductDataRoom(
        { ...updatedValues, product_id: pid },
        cookies.t
      );

      if (res) {
        if (link) {
          toast(
            "Video Upload is currently in progress, Please don't refresh the page.",
            { duration: 1500, style: warningStyles }
          );
        } else {
          toast.success("Data Added Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
        }

        if (vimeoRes) {
          localStorage.setItem("in-process", res?.data?.data?.id);
        }

        if (link) {
          handleWorker({
            id: res?.data?.data?.id,
            upload_link: vimeoRes.data?.uploadUri,
            videoUri: vimeoRes?.data?.videoUri,
            videoFile: videoFile,
            filename,
            videoId,
          });
        }

        // Trigger additional actions after success
        onAddorUpdate();
        onClose();
      }
    } catch (error) {
      console.log("Error adding product data:", error);
    } finally {
      setIsSaving(false);
      dispatch(setLoading(false));
    }
  };

  const handleEdit = async (values) => {
    setIsSaving(true);
    dispatch(setLoading(true));

    let videoUploadResult = { success: true, updatedValues: values };

    if (values.type === "Video" && values.document && !values.link) {
      videoUploadResult = await handleVideoUpload(values);
      if (!videoUploadResult.success) {
        setIsSaving(false);
        dispatch(setLoading(false));
        return; // Early exit in case of video upload error
      }
    }

    let { updatedValues, link, vimeoRes, videoFile, filename, videoId } =
      videoUploadResult;

    if (
      (values.type === "Video" || values.type === "Podcast") &&
      values.link &&
      !values.thumbnail
    ) {
      const thumbnail = await handleLinkBlur(null, values.link);

      updatedValues = { ...values, thumbnail };
    }

    try {
      const res = await editProductDataRoom(
        { ...updatedValues, id: initialData?.data_id },
        cookies.t
      );

      if (res.status) {
        if (link) {
          toast(
            "Video Upload is currently in progress, Please don't refresh the page.",
            { duration: 1500, style: warningStyles }
          );
        } else {
          toast.success("Data Updated Successfully", {
            duration: 1000,
            style: confimationStyles,
          });
        }

        // console.log("id", res);

        if (link) {
          handleWorker({
            id: res?.data?.id,
            upload_link: vimeoRes.data?.uploadUri,
            videoUri: vimeoRes?.data?.videoUri,
            videoFile: videoFile,
            filename,
            videoId,
          });
        }

        onAddorUpdate();
        onClose();
      }
    } catch (error) {
      console.error("Error editing product data:", error);
    } finally {
      setIsSaving(false);
      dispatch(setLoading(false));
    }
  };

  const handleLinkBlur = async (setFieldValue, item) => {
    const vimeoRegex =
      /https:\/\/(?:player\.)?vimeo\.com\/(?:video\/|manage\/videos\/)?(\d+)/;
    const vimeoMatch = item.match(vimeoRegex);

    if (vimeoMatch) {
      try {
        const videoId = item.split("/").pop();

        if (videoId) {
          const thumbnailResponse = await fetchThumbnail({
            token: cookies.t,
            video_id: videoId,
          });

          const thumbnailUrl = thumbnailResponse?.thumbnailUrl;

          if (setFieldValue) {
            setFieldValue("thumbnail", thumbnailUrl);
          } else {
            return thumbnailUrl;
          }
        }
      } catch (error) {
        console.error("Error fetching video details:", error);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      key={id}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({
        dirty,
        isValid,
        values,
        setFieldValue,
        handleBlur,
        handleChange,
        touched,
        errors,
        setFieldTouched,
      }) => (
        <Form>
          <div className="row mt-3">
            <InputLayout>
              <label htmlFor="" className="required-field">
                Name
              </label>
              <Field
                type="text"
                name="name"
                id="name"
                placeholder="Enter Name"
                className={`${
                  touched.name && errors.name ? "error-input" : ""
                }`}
              />
              {touched.name && errors.name && <Error error={errors.name} />}
            </InputLayout>

            <InputLayout>
              <div className="add-page-switch" style={{ marginTop: "31px" }}>
                <label htmlFor="" className="mb-0">
                  Is Featured
                </label>
                <div className="form-switch">
                  {values.is_featured ? "Yes" : "No"}
                  <input
                    className="form-check-input ms-3"
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    name="is_featured"
                    checked={values.is_featured}
                    value={values.is_featured}
                    onChange={(e) => {
                      values.is_featured = !values.is_featured;
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                  />
                </div>
              </div>
            </InputLayout>
          </div>

          <div className="row g-3 mt-1">
            <InputLayout>
              <label htmlFor="" className="required-field">
                Type
              </label>
              <SelectDropdown
                data={typeData}
                onChange={(option) => setFieldValue("type", option)}
                placeholder="Select Type"
                selectedValue={values?.type}
              />
            </InputLayout>

            <InputLayout>
              <label htmlFor="date">Updated Date</label>
              <DateSelector
                placeholder="Enter Date"
                onChange={(selected) => setFieldValue("date", selected)}
                selectedDate={values?.date}
              />
            </InputLayout>
          </div>

          {values.type === "Document" && (
            <div className="form-group mt-3">
              <label htmlFor="" className="required-field">
                Sub Type
              </label>
              <SelectDropdown
                data={subTypeData}
                onChange={(option) => {
                  setFieldValue("sub_type", option);
                  setFieldValue("document", null);
                }}
                placeholder="Select Sub-Type"
                selectedValue={values?.sub_type}
              />
            </div>
          )}

          {values.type === "Document" && values.sub_type && (
            <div className="form-group mt-3">
              <label htmlFor="" className="required-field">
                Document Upload
              </label>
              <CustomDropzone
                onFileSelect={(file) => setFieldValue("document", file)}
                key={values.sub_type}
                adType={
                  values.sub_type === "Pdf"
                    ? "Pdf"
                    : values.sub_type === "Excel"
                    ? "Excel"
                    : "Others"
                }
                selectedFile={initialData?.document}
                maxFileSizeMB={20}
              />
            </div>
          )}

          {(values.type === "Video" || values.type === "Podcast") && (
            <div className="form-group mt-3">
              <label htmlFor="" className="required-field">
                {values.type} Link
              </label>
              <Field
                type="url"
                name="link"
                id="link"
                placeholder="Enter Link"
                onBlur={(e) => {
                  handleBlur(e);
                  if (!errors.link) {
                    handleLinkBlur(setFieldValue, values.link);
                  }
                }}
              />
            </div>
          )}

          {(values.type === "Podcast" || values.type === "Video") && (
            <>
              <p className="w-100  mt-3 mb-0" style={{ textAlign: "center" }}>
                Or
              </p>
              <div className="form-group mt-2">
                <label htmlFor="">
                  {values.type === "Podcast" ? "Audio Upload" : "Video Upload"}
                </label>
                <CustomDropzone
                  onFileSelect={(file) => setFieldValue("document", file)}
                  key={values.sub_type}
                  adType={values.type === "Video" ? "Video" : "Audio"}
                  maxFileSizeMB={values.type === "Video" ? 1000 : 100}
                  selectedFile={initialData?.document}
                />
              </div>

              {/* {console.log("err", errors)} */}

              {values.type === "Podcast" && errors.link && (
                <Error error={errors.link} />
              )}

              {values.type === "Video" && errors.link && (
                <Error error={errors.link} />
              )}

              {values.type === "Podcast" && (
                <div className="form-group mt-3">
                  <label htmlFor="">
                    Choose Thumbnail{" "}
                    {values.type === "Podcast" && values.document && "*"}
                  </label>
                  {/* <CustomFileInput
                  initialFileName={getFileName(
                    initialValues?.podcast_thumbnail
                  )}
                  onChange={(event) => {
                    setFieldValue(
                      "podcast_thumbnail",
                      event.currentTarget.files[0]
                    );
                  }}
                  onBlur={(e) => {
                    setFieldTouched("podcast_thumbnail", true);
                  }}
                  isError={
                    touched.podcast_thumbnail && errors.podcast_thumbnail
                  }
                />
                {touched.podcast_thumbnail && errors.podcast_thumbnail && (
                  <Error error={errors.podcast_thumbnail} />
                )} */}
                  <ImageSelector
                    type="Company"
                    style={ImageSelectorStyle}
                    selectedImage={initialValues?.podcast_thumbnail}
                    aspect={2}
                    description="Thumbnail should be 600X400 and size is 1MB"
                    handleFile={(file) => {
                      setFieldValue("podcast_thumbnail", file);
                    }}
                    onRemove={(file) => {
                      setFieldValue("podcast_thumbnail", null);
                    }}
                  />
                  {errors.podcast_thumbnail && (
                    <Error error={errors.podcast_thumbnail} />
                  )}
                </div>
              )}
            </>
          )}

          <div className="form-group mt-3">
            <label htmlFor="">Tag</label>
            {/* <Field name="tag" type="text" id="tag" placeholder="Enter tag" /> */}
            <TagInput
              placeholder="Enter Tag"
              onChange={(values) => {
                setFieldValue("tag", values);
              }}
              selectedTags={initialData?.tag || []}
              id={initialData?.id || ""}
            />
          </div>

          <div className="form-group mt-3">
            {/* <label htmlFor="">Description</label>
            <Field
              type="text"
              name="description"
              id="description"
              as="textarea"
              placeholder="Enter Description"
            /> */}

            <QuillEditor
              classes="mt-0 mb-0"
              label="Description"
              placeholderText="Enter Description"
              onChange={(content) => {
                setFieldValue("description", content);
              }}
              value={initialData?.description}
              type=""
            />
          </div>

          <div className="d-flex justify-content-end mt-small mb-small">
            {initialData ? (
              <button
                type="button"
                className="btn btn-primary px-5 w-20"
                onClick={() => handleEdit(values)}
                disabled={!isValid || isSaving}
              >
                Update
                {isSaving && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary px-5 w-20"
                onClick={() => handleSubmit(values)}
                disabled={!isValid || !dirty || isSaving}
              >
                Add
                {isSaving && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

const typeData = [
  { label: "Document", value: "Document" },
  { label: "Video", value: "Video" },
  { label: "Podcast", value: "Podcast" },
];

export default AddDataForm;
