import moment from "moment";
import { useNavigate } from "react-router-dom";
import CircleAvatar from "../../../../atoms/CircleAvatar";
import InputLayout from "../../../../atoms/InputLayout";
import PageCard from "../../../../atoms/PageCard";
import RightUpArrow from "../../../../Icons/RightUpArrow";

const PreferencesNotes = ({ data, people_note }) => {
  const navigate = useNavigate();

  return (
    <>
      <h3 className="h3-j mb-3">
        {data?.length + people_note?.length === 1
          ? "Preference Note"
          : "Preference Notes"}
      </h3>

      {people_note?.length > 0 &&
        people_note.map((item: any) => (
          <PageCard
            key={Math.random()} // Add a unique key for each element
            customStyles={{ backgroundColor: "#F9F9F9" }}
            classes="p-4 mb-4"
          >
            <div className="d-flex gap-2">
              <CircleAvatar
                name={item.people_name}
                size="46px"
                fontSize="15px"
                color="#fff"
                iconColor="#3D9BE9"
                border="1px solid #efefef"
              />

              <div>
                <label className="mb-0">{item?.people_name}</label>
                <div className="p-grey" style={{ lineHeight: "1.2rem" }}>
                  {item?.role}
                </div>
              </div>
            </div>

            <hr />

            {item?.notes_object?.map((note) => (
              <>
                <div className="row g-3">
                  <InputLayout>
                    <div className="p-dark-product-company">Title</div>
                    <div className="p-light-small-product-company d-flex gap-2">
                      {note?.title}
                      <span
                        className="p companyNameArrow"
                        onClick={() => {
                          navigate(
                            `/dashboard/people/${item?.id}#${note?.id}`,
                            { state: { open: "Preference Notes" } }
                          );
                        }}
                      >
                        {data?.people_overview?.company_name}
                        <div className="rightUpArrow">
                          <RightUpArrow />
                        </div>
                      </span>
                    </div>
                  </InputLayout>

                  {note?.date && (
                    <InputLayout>
                      <div className="p-dark-product-company">Date</div>
                      <div className="p-light-small-product-company d-flex gap-1">
                        {moment(note?.date).format("MMMM YYYY") || "--"}
                      </div>
                    </InputLayout>
                  )}
                </div>
                <hr />
              </>
            ))}
          </PageCard>
        ))}
      {data?.length > 0 &&
        data.map((item: any) => (
          <PageCard
            key={item?.id || Math.random()} // Add a unique key for each element
            customStyles={{ backgroundColor: "#F9F9F9" }}
            classes="p-4 mb-4"
          >
            <div className="row g-3">
              <InputLayout classes="w-100">
                <div className="p-dark-product-company">Title</div>
                <div className="p-light-small-product-company">
                  {item?.title || "--"}
                </div>
              </InputLayout>

              {item?.date && (
                <InputLayout>
                  <div className="p-dark-product-company">Date</div>
                  <div className="p-light-small-product-company">
                    {moment(item?.date).format("MMMM YYYY") || "--"}
                  </div>
                </InputLayout>
              )}
            </div>

            <InputLayout classes="mt-4">
              <div className="p-dark-product-company">Note</div>
              <div className="p-light-small-product-company">
                {item?.notes || "--"}
              </div>
            </InputLayout>
          </PageCard>
        ))}
    </>
  );
};

export default PreferencesNotes;
